import React from 'react';
import styled from 'styled-components';
import { layout, color, flexbox } from 'styled-system';
import { Spring } from 'react-spring/renderprops';

export const Card = styled.div`
  background: ${(props) => props.bg || '#36516C'};
  ${(props) =>
    props.bgRight && {
      backgroundImage: `linear-gradient(to right, ${props.bg}, ${props.bgRight})`,
    }}
  padding: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 2px rgba(0,0,0,0.2);
  overflow: hidden;
  position: relative;
  font-family: 'Boston';
  color: white;

  ${layout}
  ${color}
  ${flexbox}
`;

export const AnimatedCard = ({ children, animation, ...props }) => (
  <Spring
    from={{ transform: 'translate3d(0,40px, 0)', opacity: 0 }}
    to={{ transform: 'translate3d(0,0px, 0)', opacity: 1 }}
    config={{ tension: 30, friction: 10 }}
    {...animation}
  >
    {(styles) => (
      <Card style={styles} {...props}>
        {children}
      </Card>
    )}
  </Spring>
);

export default AnimatedCard;

// @ts-check
import React from 'react';
import { Switch } from 'react-router-dom';
import Feedback from '../Feedback';
import MainToolbar from './components/MainToolbar';
import Dashboard from './Dashboard';
import DashboardExtra from './DashboardExtra';
import PrivateRoute from './PrivateRoute';
import Sales from './Sales';


import { useSelector, useDispatch } from 'react-redux';
import Prices from './Prices';


export default function MainPage() {
  const profileName = useSelector((store) => store.user.profile.name);

  if (profileName === 'Vendedor') {
    return (
      <>
        <MainToolbar />
        <Switch>
          <PrivateRoute exact path="/">
            <DashboardExtra />
          </PrivateRoute>
          <PrivateRoute path="/precios" >
            <Prices />
          </PrivateRoute>
        </Switch>
        <Feedback />
      </>
    );
  }

  else {
  return (
    <>
      <MainToolbar />
      <Switch>
        <PrivateRoute exact path="/">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/ventas" >
          <Sales />
        </PrivateRoute>
      </Switch>
      <Feedback />
    </>
  );
  }

}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

import gql from 'graphql-tag';

export const getPrices = gql`
  query GetPrices($brand: String, $product: String, $storesfilter: [String], $stores: [String]) {
    getModuloPrecios(
      Marca: $brand
      Producto: $product
      TiendasFiltro: $storesfilter
      Tiendas: $stores
    ) {
      Exito
      Condicion
      Excepcion

      SubmoduloPreciosDetalle {
        IDCodigoBarra
        IDArticuloColorTalle
        IDArticulo
        IDColor
        IDTalle
        Articulo
        Color
        Talle
        ColorBase
        TalleBase
        Costo
        Precio
        Precio2
        Precio3
        Stock
        Imagen
      }
      SubmoduloPreciosCombinaciones { 
        ListaColores {
          IDColor
          Color
          ColorBase
        }
        ListaTalles {
          IDTalle
          Talle
          TalleBase
        }  
        ListaCombinacionesTiendasFiltro {
          IDArticuloColorTalle
          IDArticulo
          IDColor
          IDTalle
          Articulo
          Color
          Talle
          ColorBase
          TalleBase
          Stock
        }
        ListaCombinacionesTiendas {
          IDTienda
          Tienda
          IDArticuloColorTalle
          IDArticulo
          IDColor
          IDTalle
          Articulo
          Color
          Talle
          ColorBase
          TalleBase
          Stock
        }
      }
    }
  }
`;

export const getSales = gql`
  query GetSales($period: String, $stores: [String], $timezone: String) {
    getModuloVentas(
      Intervalo: $period
      Timezone: $timezone
      Tiendas: $stores
      Submodulos: [
        "Ventas"
        "Metricas"
        "Ventas Distribucion"
        "Ventas Sucursales"
      ]
    ) {
      Exito
      Condicion
      Excepcion

      SubmoduloVentas {
        Titulo
        Valor
        Crecimiento
        ListaMediosPago {
          Titulo
          Valor
        }
      }
      SubmoduloMetricas {
        ListaMetricas {
          Titulo
          Valor
          Crecimiento
          Icono
        }
      }
      SubmoduloVentasDistribucion {
        ListaVentasDistribucion {
          Titulo
          ValorActual
          ValorAnterior
        }
      }
      SubmoduloVentasSucursales {
        ListaVentasSucursales {
          Titulo
          ValorActual
          ValorAnterior
        }
      }
    }
  }
`;

export const getDashboard = gql`
  query GetModules($period: String, $timezone: String, $stores: [String]) {
    getDashboard(
      Intervalo: $period
      Timezone: $timezone
      Tiendas: $stores
      Modulos: ["Ventas", "Demanda", "Stock", "Objetivos", "Precios"]
    ) {
      Exito
      Condicion
      Excepcion

      ModuloVentas {
        Titulo
        Crecimiento
      }
      ModuloDemanda {
        Titulo
        ListaDemandaPodio {
          Titulo
          Icono
        }
      }
      ModuloStock {
        Titulo
        ListaStockPodio {
          Titulo
          Icono
        }
      }
      ModuloObjetivos {
        Titulo
        Crecimiento
      }
      ModuloPrecios {
        Titulo
        Crecimiento
      }
    }
  }
`;

export const getConfiguracion = (user) => /* GraphQL */ `
  query getConfiguracion {
    getConfiguracion(Usuario: "${user}") {
      Exito
      Condicion
      Excepcion

      DatosUsuario {
        Usuario
        IDCliente
        IDPerfil
      }
      DatosCliente {
        IDCliente
        Cliente
        IDPerfilNivel
        PerfilNivel
        IDValorPerfilNivel
        ValorPerfilNivel
      }
      DatosPerfil {
        IDPerfilBI
        PerfilBI
        IDPerfilNivel
        PerfilNivel
        IDPerfilCargo
        PerfilCargo
        IDPerfilTiendas
        ListaTiendas {
          IDEmpresa
          Empresa
          IDTienda
          Apodo
          Ciudad
          Provincia
          Pais
        }
        IDPerfilModulos
        ListaModulos {
          IDModulo
          Modulo
          ListaSubmodulos {
            IDSubmodulo
            Submodulo
          }
        }
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import { DiffNumber } from '../components/Diff';
import { Text } from '../../components/Base';
import { ReactComponent as ArrowIcon } from '../../icons/indicator.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 230px;
  height: 162px;
  padding: 0.8rem 1.6rem;
  background: #3c5c7a;
  border-radius: 18px;
  box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.09);
  position: relative;

  .separator {
    background: #35516d;
    width: 100%;
    height: 1px;
    margin-top: 12px;
  }

  .line {
    border-radius: 6px;
    height: 8px;
    width: 100%;
    background: #2e4960;
    display: flex;

    .low {
      width: 30%;
    }
    .optimal {
      width: 40%;
      background: #4d7691;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -2px;
        width: 2px;
        height: 12px;
        border-radius: 2px;
        background: #4d7691;
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: -2px;
        width: 2px;
        height: 12px;
        border-radius: 2px;
        background: #4d7691;
      }
    }
    .high {
      width: 30%;
    }
  }

  .labels {
    color: #57889e;
    font-size: 8px;
    text-transform: uppercase;
    display: flex;
    width: 100%;
    white-space: nowrap;
    margin: 4px 0;
    position: relative;

    .low {
      width: 30%;
      text-align: center;
    }
    .optimal {
      width: 40%;
      text-align: center;
    }
    .high {
      width: 30%;
      text-align: center;
    }
  }

  .arrow {
    transform: rotate(180deg);
    height: 15px;
    width: 15px;
    color: ${(props) => (props.negative ? '#fc5c1f' : '#00ff00')};
    position: absolute;
    left: 56%;
  }
`;

const StockBalance = ({ value }) => {
  return (
    <Wrapper>
      <Text fontSize={34}>
        <DiffNumber number={value} />
      </Text>
      <hr className="separator" />
      <div className="labels">
        <ArrowIcon className="arrow" />
        <div className="low">Escaso</div>
        <div className="optimal" style={{ visibility: 'hidden' }}>
          Óptimo
        </div>
        <div className="high">Exceso</div>
      </div>
      <div className="line">
        <div className="low" />
        <div className="optimal" />
        <div className="high" />
      </div>
    </Wrapper>
  );
};
export default StockBalance;

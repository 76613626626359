import styled from 'styled-components';
import { Box, Flex } from '../../components/Base';

export const TableItem = styled(Flex)`
  background: #3e637c;
  padding: 5px 7px;
  border-radius: 6px;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    margin: 0;
  }

  .avatar {
    background: #4d7691;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    display: inline-block;
    overflow: hidden;
    border: 3px solid #4d7691;
    img {
      display: inline-block;
    }
  }

  .icon {
    background: #35516d;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    display: inline-flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }
`;

export const TableList = styled(Box)`
  background: #3c5c7a;
  border-radius: 10px;
  padding: 8px;
  box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.09);
  /* 1px 1px 4px rgba(0, 0, 0, 0.08); */
`;

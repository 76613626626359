// @ts-check

import React, { useState, useEffect } from 'react';
import { format as d3format } from 'd3-format';
import { useSelector } from 'react-redux';

import PrototypeWrapper from './Wrapper';
import {
  BarChart,
  Bar,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Flex, Box, Text } from '../components/Base';
import Card from '../components/Card';
import CardTitle from './components/CardTitle';
import Highlight from './components/Highlight';
import { DiffNumber, DiffBox } from './components/Diff';
import { SalesTooltip } from './components/SalesTooltip';

import { ReactComponent as SalesIcon } from '../icons/money.svg';
import { ReactComponent as MoneySign } from '../icons/money-3.svg';
import { ReactComponent as MoneyBill } from '../icons/money-bill.svg';
import { ReactComponent as Ticket } from '../icons/ticket.svg';
import { ReactComponent as BoxIcon } from '../icons/box.svg';
import { ReactComponent as UnitKg } from '../icons/unit-kg.svg';
import { ReactComponent as CardIcon } from '../icons/card.svg';
import { ReactComponent as CouponIcon } from '../icons/coupon.svg';
import { ReactComponent as ClockIcon } from '../icons/clock.svg';

import { API, graphqlOperation } from 'aws-amplify';
import { getSales } from '../graphql/queries';

import Loader from '../Prototype/components/Loader';
import useWindowDimensions from '../utils/hooks/useWindowDimensions';
import { ChevronRight } from 'styled-icons/feather/ChevronRight';
import { ChevronLeft } from 'styled-icons/feather/ChevronLeft';
import { tabletBreakpoint } from '../utils/breakpoints';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../components/ErrorMessage';

const legends = {
  ValorAnterior: 'Anterior',
  ValorActual: 'Actual',
};

const legends2 = {
  ValorActual: 'Ventas',
  uv: 'Objetivos',
  ValorAnterior: 'Anterior',
};

function Sales() {
  const { period, store } = useSelector((store) => store.filters);

  const { stores } = useSelector((store) => store.user);
  const [salesAreLoading, setSalesAreLoading] = useState(true);
  const [salesRequestError, setSalesRequestError] = useState(false);
  const [storePage, setStorePage] = useState(0);
  const [storePageSize, setStorePageSize] = useState(3);
  const [sales, setSales] = useState();
  const { height, width } = useWindowDimensions();
  const history = useHistory();

  /**
   * Request sales
   */
  useEffect(() => {
    setSalesAreLoading(true);
    API.graphql(
      graphqlOperation(getSales, {
        period: period.label,
        timezone: (-new Date().getTimezoneOffset() / 60).toString(),
        stores:
          store.Apodo === 'Todos'
            ? stores.map((store) => store.IDTienda)
            : [store.IDTienda],
      })
    )
      .then((response) => {
        setSales(response.data);
        setSalesAreLoading(false);
        setStorePage(0);
      })
      .catch((error) => {
        if (error === 'No current user') history.push('/login'); // TODO verify token refresh
        setSalesRequestError(true);
        setSalesAreLoading(false);
        setStorePage(0);
      });
  }, [store, period, stores]);

  /**
   * change the number of bars if the with of the screen is less than the table brackpoint
   */
  useEffect(() => {
    width < tabletBreakpoint ? setStorePageSize(3) : setStorePageSize(5);
  }, [width]);

  if (salesAreLoading) {
    return <Loader></Loader>;
  }

  if (salesRequestError) {
    return <ErrorMessage />;
  }

  return (
    <PrototypeWrapper>
      <Box className="boxes">
        <Card border="#00C6FF" animation={{ delay: 0 }} px="32px">
          <CardTitle>Ventas</CardTitle>
          <Box width={1} style={{ textAlign: 'center' }}>
            <Flex alignItems="center" justifyContent="center">
              <Box
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  marginRight: '20px',
                }}
              >
                {width > tabletBreakpoint ? (
                  <Text fontSize={52} lineHeight={1.4} textAlign="center">
                    {sales.getModuloVentas.SubmoduloVentas.Valor}
                  </Text>
                ) : (
                  <Text fontSize={32} lineHeight={1.4} textAlign="center">
                    {sales.getModuloVentas.SubmoduloVentas.Valor}
                  </Text>
                )}
              </Box>
              <DiffBox
                fontSize={17}
                number={sales.getModuloVentas.SubmoduloVentas.Crecimiento}
                ml={24}
              />
            </Flex>
            <Flex justifyContent="center" flexWrap="wrap">
              {sales.getModuloVentas.SubmoduloVentas.ListaMediosPago.map(
                ({ Titulo, Valor }) => (
                  <Highlight key={Titulo}>
                    <div className="icon">
                      {Titulo === 'EFECTIVO' ? (
                        <MoneyBill />
                      ) : Titulo === 'TARJETA' ? (
                        <CardIcon />
                      ) : Titulo === 'OTROS' ? (
                        <CouponIcon />
                      ) : (
                        <CouponIcon />
                      )}
                    </div>
                    <Box width={1}>
                      <div className="title">{Titulo}</div>
                      <Flex className="values">
                        <div>{Valor}</div>
                      </Flex>
                    </Box>
                  </Highlight>
                )
              )}
            </Flex>
          </Box>
        </Card>
        <Card border="#00C6FF" animation={{ delay: 200 }} px="32px">
          <CardTitle>Distribución de Ventas</CardTitle>
          <Flex width={1} height="100%">
            {/* <Flex
              width={0.2}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pt={2}
            >
              <ClockIcon />
              <Text fontSize={16} color="#748599">
                Hora Pico
              </Text>
              <Text fontSize={[28, 28, 28, 28, 36]} fontWeight={300} mt={16}>
                18:00hs
              </Text>
            </Flex> */}
            <Box width={0.8} width="100%" height="250px" p="20px 0 0 0">
              <ResponsiveContainer style={{ marginTop: 56 }}>
                <LineChart
                  data={
                    sales.getModuloVentas.SubmoduloVentasDistribucion
                      .ListaVentasDistribucion
                  }
                  margin={{ top: -32, right: 10, bottom: 0, left: -16 }}
                >
                  <Legend
                    verticalAlign="top"
                    align="right"
                    height={48}
                    iconType="plainline"
                    formatter={(value, entry) => (
                      <Text as="span" color="#8699AD" fontSize={11}>
                        {legends[value]}
                      </Text>
                    )}
                  />
                  <XAxis
                    dataKey="Titulo"
                    stroke="rgba(0,0,0,0.04)"
                    interval={0}
                    width={30}
                    tick={({ x, y, payload }) => (
                      <g transform={`translate(${x}, ${y + 5})`}>
                        <text
                          x={0}
                          y={0}
                          dy={3}
                          textAnchor="middle"
                          fill="#8699AD"
                          style={{
                            fontSize: 10,
                          }}
                        >
                          {payload.value}
                        </text>
                      </g>
                    )}
                  />
                  <YAxis
                    stroke="rgba(0,0,0,0.04)"
                    tick={({ x, y, payload }) => (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={0}
                          y={0}
                          dy={3}
                          textAnchor="end"
                          fill="#8699AD"
                          style={{
                            textTransform: 'uppercase',
                            fontSize: 11,
                          }}
                        >
                          {d3format('.2s')(payload.value)}
                        </text>
                      </g>
                    )}
                  />
                  <Line
                    type="monotone"
                    dataKey="ValorAnterior"
                    stroke="#4D7691"
                    strokeWidth={2}
                    activeDot={{ r: 8 }}
                    dot={false}
                    activeDot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="ValorActual"
                    stroke="#00C6FF"
                    strokeWidth={2}
                    dot={false}
                  />
                  <Tooltip
                    content={<SalesTooltip></SalesTooltip>}
                    position={{ y: 10 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Flex>
        </Card>
        <Card border="#00C6FF" animation={{ delay: 400 }} px="32px">
          <CardTitle>Métricas</CardTitle>
          <Flex
            justifyContent="space-around"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            {sales.getModuloVentas.SubmoduloMetricas.ListaMetricas.map(
              ({ Titulo, Valor, Crecimiento }) => (
                <Highlight key={Titulo}>
                  <div className="icon">
                    {Titulo === 'MONTO' ? (
                      <MoneySign />
                    ) : Titulo === 'TICKETS' || Titulo === 'TICKET PROM.' ? (
                      <Ticket />
                    ) : Titulo === 'UNIDADES' || Titulo === 'UNID. PROM.' ? (
                      <BoxIcon />
                    ) : Titulo === 'KILOS' ? (
                      <UnitKg />
                    ) : Titulo === 'DESCUENTOS' ||
                      Titulo === 'RECARGOS' ||
                      Titulo === 'PROMOCIONES' ||
                      Titulo === 'COMBOS' ? (
                      <CouponIcon />
                    ) : (
                      <MoneySign />
                    )}
                  </div>
                  <Box width={1}>
                    <div className="title">{Titulo}</div>
                    <Flex className="values">
                      <div style={{ marginRight: '10px' }}>{Valor}</div>
                      <DiffNumber number={Crecimiento} />
                    </Flex>
                  </Box>
                </Highlight>
              )
            )}
          </Flex>
        </Card>
        <Card border="#00C6FF" animation={{ delay: 600 }} px="32px">
          <CardTitle>Venta sucursales</CardTitle>
          <Flex alignItems="center" justifyContent="center">
            <Box width="100%" height="250px" p="20px 0 0 0">
              <ResponsiveContainer>
                <BarChart
                  data={sales.getModuloVentas.SubmoduloVentasSucursales.ListaVentasSucursales.slice(
                    storePage * storePageSize,
                    storePage * storePageSize + storePageSize
                  )}
                  margin={{ top: -32, right: 10, bottom: 0, left: -16 }}
                  barGap={0}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="-15%" stopColor="#00D6FF" stopOpacity={1} />
                      <stop offset="125%" stopColor="#0097FF" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <Tooltip
                    cursor={{ fill: '#36516C' }}
                    position={{ y: -5 }}
                    // TODO improve the reverse
                    content={(data) => (
                      <SalesTooltip
                        {...{ ...data, payload: data.payload.reverse() }}
                      ></SalesTooltip>
                    )}
                  ></Tooltip>
                  <Legend
                    verticalAlign="top"
                    align="right"
                    height={48}
                    iconType="square"
                    formatter={(value, entry) => (
                      <Text as="span" color="#8699AD" fontSize={11}>
                        {legends2[value]}
                      </Text>
                    )}
                  />
                  <XAxis
                    dataKey="Titulo"
                    stroke="rgba(0,0,0,0.04)"
                    interval={0}
                    width={30}
                    tick={({ x, y, payload }) => (
                      <g transform={`translate(${x}, ${y + 5})`}>
                        <text
                          x={0}
                          y={0}
                          dy={3}
                          textAnchor="middle"
                          fill="#8699AD"
                          style={{
                            fontSize: 10,
                          }}
                        >
                          {payload.value}
                        </text>
                      </g>
                    )}
                  />
                  <YAxis
                    stroke="rgba(0,0,0,0.04)"
                    tick={({ x, y, payload }) => (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={0}
                          y={0}
                          dy={3}
                          textAnchor="end"
                          fill="#8699AD"
                          style={{
                            textTransform: 'uppercase',
                            fontSize: 11,
                          }}
                        >
                          {d3format('.2s')(payload.value)}
                        </text>
                      </g>
                    )}
                  />
                  <Bar
                    dataKey="ValorActual"
                    radius={[3, 3, 0, 0]}
                    barSize={22}
                    fill="#00C6FF"
                  ></Bar>
                  <Bar
                    dataKey="ValorAnterior"
                    fill="#4D7691"
                    radius={[3, 3, 0, 0]}
                    barSize={22}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <ChevronLeft
              onClick={() =>
                setStorePage((storePage) =>
                  storePage - 1 >= 0 ? storePage - 1 : storePage
                )
              }
              style={{
                stroke: 'rgba(255,255,255,0.28)',
                height: 34,
                width: 34,
                cursor: 'pointer',
              }}
            />
            <div
              style={{
                color: 'rgba(255,255,255,0.28)',
              }}
            >
              Página {storePage + 1} de{' '}
              {Math.round(
                (sales.getModuloVentas.SubmoduloVentasSucursales
                  .ListaVentasSucursales.length +
                  1) /
                storePageSize
              )}
            </div>
            <ChevronRight
              onClick={() =>
                setStorePage((storePage) =>
                  storePage * storePageSize + storePageSize <
                    sales.getModuloVentas.SubmoduloVentasSucursales
                      .ListaVentasSucursales.length
                    ? storePage + 1
                    : storePage
                )
              }
              style={{
                stroke: 'rgba(255,255,255,0.28)',
                height: 34,
                width: 34,
                cursor: 'pointer',
              }}
            />
          </Flex>
        </Card>
      </Box>
    </PrototypeWrapper>
  );
}

export default Sales;

import styled from 'styled-components';
import { Flex } from '../../components/Base';

const HighlightTableColumns = styled(Flex)`
  border-radius: 10px;
  color: white;
  font-size: 12px;
  line-height: 1;
  margin: 5px;
  padding: 5px 5px 5px 5px;
  align-items: center;
  text-align: center; 
  width: 50px;
  border: 1px solid #2f455c;
  min-width: 50px;
  display: inline-block;
`;

export default HighlightTableColumns;
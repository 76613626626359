import React from 'react';
import { format as d3format } from 'd3-format';
import PrototypeWrapper from './Wrapper';
import {
  BarChart,
  Bar,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Text, Flex, Box } from '../components/Base';
import Card from '../components/Card';
import CardTitle from './components/CardTitle';
import { TableList, TableItem } from './components/TableList';
import Status from './components/Status';

import { ReactComponent as DemandIcon } from '../icons/trends.svg';
import { ReactComponent as CatIce1Icon } from '../icons/cat_ice_1.svg';
import { ReactComponent as CatIce4Icon } from '../icons/cat_ice_4.svg';
import { ReactComponent as CatIce5Icon } from '../icons/cat_ice_5.svg';
import { ReactComponent as CatSundaeIcon } from '../icons/cat_sundae.svg';

const data1 = [
  {
    name: '9a',
    uv: 4000,
    pv1: 2400,
    amt: 2400,
  },
  {
    name: '10a',
    uv: 3000,
    pv1: 1398,
    amt: 2210,
  },
  {
    name: '11a',
    uv: 2000,
    pv1: 9800,
    amt: 2290,
  },
  {
    name: '12p',
    uv: 2780,
    pv1: 3908,
    amt: 2000,
  },
  {
    name: '1p',
    uv: 1890,
    pv1: 4800,
    amt: 2181,
  },
  {
    name: '2p',
    uv: 2390,
    uv2: 2390,
    pv1: 3800,
    amt: 2500,
  },
  {
    name: '3p',
    uv: null,
    uv2: 3490,
    pv1: 4300,
    amt: 2100,
  },
  {
    name: '4p',
    uv: null,
    uv2: 2300,
    pv1: 3900,
    amt: 2100,
  },
  {
    name: '5p',
    uv: null,
    uv2: 4700,
    pv1: 3900,
    amt: 2100,
  },
  {
    name: '6p',
    uv: null,
    uv2: 5600,
    pv1: 5100,
    amt: 2100,
  },
  {
    name: '7p',
    uv: null,
    uv2: 7800,
    pv1: 3900,
    amt: 2100,
  },
  {
    name: '8p',
    uv: null,
    uv2: 4500,
    pv1: 3900,
    amt: 2100,
  },
];

const data2 = [
  {
    name: 'Palitos',
    uv: 4000,
    pv: 2400,
  },
  {
    name: 'Cucuruchos',
    uv: 3000,
    pv: 1398,
  },
  {
    name: 'Helado común',
    uv: 12000,
    pv: 9800,
  },
  {
    name: 'Especiales',
    uv: 4000,
    pv: 3908,
  },
  {
    name: 'Familiar',
    uv: 5891,
    pv: 4800,
  },
  {
    name: 'Tortas',
    uv: 4000,
    pv: 3800,
  },
  {
    name: 'Postres',
    uv: 4500,
    pv: 4300,
  },
  // {
  //   name: 'Semifríos',
  //   uv: 7000,
  //   pv: 3900,
  // },
  // {
  //   name: 'Cafetería',
  //   uv: 8000,
  //   pv: 3900,
  // },
  // {
  //   name: 'Frizzio',
  //   uv: 5400,
  //   pv: 5100,
  // },
].sort((a, b) => b.pv - a.pv);

const legends = {
  pv: 'Período actual',
  uv: 'Período anterior',
};

const Demanda = () => (
  <PrototypeWrapper>
    <Box className="boxes">
      <Card border="#19A97D" animation={{ delay: 0 }}>
        <CardTitle>
          <span
            className="icon"
            style={{ background: 'rgba(84, 214, 128, 0.2)' }}
          >
            <DemandIcon style={{ height: 11, color: '#54D680' }} />
          </span>
          Historial Demanda
        </CardTitle>
        <Box width={0.93} height="100%" p="44px 0 16px">
          <ResponsiveContainer>
            <LineChart
              data={data1}
              style={{
                borderRadius: 10,
                background: '#3C5C7A',
                boxShadow: '1px 1px 9px 2px rgba(0, 0, 0, 0.09)',
              }}
              margin={{ top: 16, right: 10, bottom: 0, left: -16 }}
            >
              <CartesianGrid
                stroke="rgba(0,0,0,0.13)"
                strokeWidth={0.5}
                style={{ padding: '0.8rem' }}
              />
              <XAxis
                dataKey="name"
                stroke="rgba(0,0,0,0.04)"
                interval={0}
                width={30}
                tick={({ x, y, payload }) => (
                  <g transform={`translate(${x}, ${y + 5})`}>
                    <text
                      x={0}
                      y={0}
                      dy={3}
                      textAnchor="middle"
                      fill="#8699AD"
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {payload.value}
                    </text>
                  </g>
                )}
              />
              <YAxis
                stroke="rgba(0,0,0,0.04)"
                tick={({ x, y, payload }) => (
                  <g transform={`translate(${x},${y})`}>
                    <text
                      x={0}
                      y={0}
                      dy={3}
                      textAnchor="end"
                      fill="#8699AD"
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {d3format('.2s')(payload.value)} u.
                    </text>
                  </g>
                )}
              />
              <Line
                type="monotone"
                dataKey="pv1"
                stroke="#FA75F8"
                strokeWidth={2}
                dot={false}
              />
              {/* <Line
                type="monotone"
                dataKey="pv2"
                stroke="#3D677A"
                strokeWidth={2}
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="pv3"
                stroke="#4F6471"
                strokeWidth={2}
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="pv4"
                stroke="#3D6486"
                strokeWidth={2}
                dot={false}
              /> */}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Card>
      <Card border="#19A97D" animation={{ delay: 400 }}>
        <CardTitle>
          <span
            className="icon"
            style={{ background: 'rgba(84, 214, 128, 0.2)' }}
          >
            <DemandIcon style={{ height: 11, color: '#54D680' }} />
          </span>
          Tendencias Rubros
        </CardTitle>
        <Box width={0.93} height="100%" p="44px 0 16px">
          <ResponsiveContainer style={{ marginTop: 56 }}>
            <BarChart
              data={data2}
              style={{
                borderRadius: 10,
                background: '#3C5C7A',
                boxShadow: '1px 1px 9px 2px rgba(0, 0, 0, 0.09)',
              }}
              margin={{ top: -32, right: 10, bottom: 0, left: -16 }}
              barGap={0}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="-15%" stopColor="#48D6D4" stopOpacity={1} />
                  <stop offset="125%" stopColor="#348F50" stopOpacity={1} />
                </linearGradient>
              </defs>
              <CartesianGrid
                stroke="rgba(0,0,0,0.13)"
                strokeWidth={0.5}
                style={{ padding: '0.8rem' }}
              />
              <Legend
                verticalAlign="top"
                align="right"
                height={48}
                iconType="circle"
                formatter={(value) => (
                  <Text as="span" color="#8699AD" fontSize={11}>
                    {legends[value]}
                  </Text>
                )}
              />
              <XAxis
                dataKey="name"
                stroke="rgba(0,0,0,0.04)"
                interval={0}
                width={30}
                tick={({ x, y, payload }) => (
                  <g transform={`translate(${x}, ${y + 5})`}>
                    <text
                      x={0}
                      y={0}
                      dy={3}
                      textAnchor="middle"
                      fill="#8699AD"
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {payload.value.split(' ').map((t, idx) => (
                        <tspan dy={`${idx}em`} x={0}>
                          {t}
                        </tspan>
                      ))}
                    </text>
                  </g>
                )}
              />
              <YAxis
                stroke="rgba(0,0,0,0.04)"
                tick={({ x, y, payload }) => (
                  <g transform={`translate(${x},${y})`}>
                    <text
                      x={0}
                      y={0}
                      dy={3}
                      textAnchor="end"
                      fill="#8699AD"
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {d3format('.2s')(payload.value)} u.
                    </text>
                  </g>
                )}
              />
              <Bar dataKey="pv" radius={6} barSize={18} fill="url(#colorUv)">
                {data2.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill="url(#colorUv)" />
                ))}
              </Bar>
              <Bar dataKey="uv" fill="#4D7691" radius={6} barSize={18} />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Card>
      <Card border="#19A97D" animation={{ delay: 200 }}>
        <CardTitle>
          <span
            className="icon"
            style={{ background: 'rgba(84, 214, 128, 0.2)' }}
          >
            <DemandIcon style={{ height: 11, color: '#54D680' }} />
          </span>
          Productos más vendidos
        </CardTitle>
        <TableList mt={24} width={0.93}>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#54D680">
                <CatIce1Icon height={16} />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Palito Bombón
              </Text>
            </Flex>
            <Text color="white" width={0.4} textAlign="center">
              50 u.
            </Text>
            <Text color="#99B0C1" width={0.3} textAlign="center">
              20%
            </Text>
            <Status bg="#FA75F8" />
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#54D680">
                <CatIce4Icon />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Tentación Cadbury
              </Text>
            </Flex>
            <Text color="white" width={0.4} textAlign="center">
              50 u.
            </Text>
            <Text color="#99B0C1" width={0.3} textAlign="center">
              20%
            </Text>
            <Status bg="#1A9CFB" />
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#54D680">
                <CatIce5Icon />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Bombón Escocés
              </Text>
            </Flex>
            <Text color="white" width={0.4} textAlign="center">
              50 u.
            </Text>
            <Text color="#99B0C1" width={0.3} textAlign="center">
              20%
            </Text>
            <Status bg="#00CF9E" />
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#54D680">
                <CatSundaeIcon />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Sundae Oreo
              </Text>
            </Flex>
            <Text color="white" width={0.4} textAlign="center">
              50 u.
            </Text>
            <Text color="#99B0C1" width={0.3} textAlign="center">
              20%
            </Text>
            <Status bg="#FC8410" />
          </TableItem>
        </TableList>
      </Card>
      <Card border="#19A97D" animation={{ delay: 600 }}>
        <CardTitle>
          <span
            className="icon"
            style={{ background: 'rgba(84, 214, 128, 0.2)' }}
          >
            <DemandIcon style={{ height: 11, color: '#54D680' }} />
          </span>
          Productos menos vendidos
        </CardTitle>
        <TableList mt={24} width={0.93}>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#54D680">
                <CatIce1Icon height={16} />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Almendrado
              </Text>
            </Flex>
            <Text color="white" width={0.4} textAlign="center">
              50 u.
            </Text>
            <Text color="#99B0C1" width={0.3} textAlign="center">
              20%
            </Text>
            <Status bg="#FA75F8" />
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#54D680">
                <CatIce4Icon />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Bombón Vainilla Split
              </Text>
            </Flex>
            <Text color="white" width={0.4} textAlign="center">
              50 u.
            </Text>
            <Text color="#99B0C1" width={0.3} textAlign="center">
              20%
            </Text>
            <Status bg="#1A9CFB" />
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#54D680">
                <CatIce5Icon />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Palito Pico Dulce
              </Text>
            </Flex>
            <Text color="white" width={0.4} textAlign="center">
              50 u.
            </Text>
            <Text color="#99B0C1" width={0.3} textAlign="center">
              20%
            </Text>
            <Status bg="#00CF9E" />
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#54D680">
                <CatSundaeIcon />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Bombón Crocante
              </Text>
            </Flex>
            <Text color="white" width={0.4} textAlign="center">
              50 u.
            </Text>
            <Text color="#99B0C1" width={0.3} textAlign="center">
              20%
            </Text>
            <Status bg="#FC8410" />
          </TableItem>
        </TableList>
      </Card>
    </Box>
  </PrototypeWrapper>
);

export default Demanda;

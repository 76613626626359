import React, { useEffect, useState, useRef } from 'react';
import { format as d3format } from 'd3-format';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';   //Ventana Popup

import PrototypeWrapper from './Wrapper';
import {
  BarChart,
  Bar,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Flex, Box, Text } from '../components/Base';
import Card from '../components/Card';
import CardTitle from './components/CardTitle';
import Highlight from './components/Highlight';

import HighlightTableColumns from './components/HighlightTableColumns';
import HighlightTableRows from './components/HighlightTableRows';
import HighlightImage from './components/HighlightImage';

import { DiffNumber, DiffBox } from './components/Diff';
import { SalesTooltip } from './components/SalesTooltip';

import { ReactComponent as SalesIcon } from '../icons/money.svg';
import { ReactComponent as MoneySign } from '../icons/money-3.svg';
import { ReactComponent as MoneyBill } from '../icons/money-bill.svg';
import { ReactComponent as Ticket } from '../icons/ticket.svg';
import { ReactComponent as BoxIcon } from '../icons/box.svg';
import { ReactComponent as UnitKg } from '../icons/unit-kg.svg';
import { ReactComponent as CardIcon } from '../icons/card.svg';
import { ReactComponent as CouponIcon } from '../icons/coupon.svg';
import { ReactComponent as ClockIcon } from '../icons/clock.svg';

import { API, graphqlOperation } from 'aws-amplify';
import { getPrices } from '../graphql/queries';

import Loader from '../Prototype/components/Loader';

import useWindowDimensions from '../utils/hooks/useWindowDimensions';
import { ChevronRight } from 'styled-icons/feather/ChevronRight';
import { ChevronLeft } from 'styled-icons/feather/ChevronLeft';
import { tabletBreakpoint } from '../utils/breakpoints';

import { useHistory } from 'react-router-dom';
import ErrorMessage from '../components/ErrorMessage';


const legends2 = {
  Stock: 'Stock'
};




function Prices() {
  const { period, store } = useSelector((store) => store.filters);

  const { stores } = useSelector((store) => store.user);
  const [pricesAreLoading, setPricesAreLoading] = useState(true);
  const [pricesRequestError, setPricesRequestError] = useState(false);


  const [storePage, setStorePage] = useState(0);
  const [storePageSize, setStorePageSize] = useState(5);
  const { height, width } = useWindowDimensions();
  const history = useHistory();


  const [prices, setPrices] = useState();
  const [scannedBarcode, setScannedBarcode] = useState('-');   //Funcion asigna valor a variable scannedBarcode, '-' va por defecto
  //const [scannedBarcode, setScannedBarcode] = useState('342089519%0135');
  //const [scannedBarcode, setScannedBarcode] = useState('341078648%0201');
  //const [scannedBarcode, setScannedBarcode] = useState('331208521%3202');
  //const [scannedBarcode, setScannedBarcode] = useState('352040560%02TU');


  const [PreciosDetalle, setPreciosDetalle] = useState('');
  const [ListaColores, setListaColores] = useState([]);
  const [ListaTalles, setListaTalles] = useState([]);
  const [ListaTiendasFiltro, setListaTiendasFiltro] = useState([]);
  const [ListaTiendas, setListaTiendas] = useState([]);



  const LongitudListaColores = ListaColores.length;
  const CantidadPaginas = Math.ceil(ListaColores.length / storePageSize);
  const PaginaActual = storePage + 1;


  const itemsPerPage = Math.max(storePageSize, Math.ceil(LongitudListaColores / CantidadPaginas));
  const startIndex = (PaginaActual - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, LongitudListaColores);




  // Variables Ventana Popup
  // Estado para controlar si el popup del stock de tiendas está abierto o cerrado
  const [isStockPopupOpen, setIsStockPopupOpen] = useState(false);

  // Estado para almacenar la información del stock de tiendas para el IDArticuloColorTalle seleccionado
  const [selectedStockInfo, setSelectedStockInfo] = useState(null);

  // Función para abrir el popup del stock de tiendas y guardar la información del stock
  const handleOpenStockPopup = (IDArticuloColorTalle) => {
    // Filtrar los datos de ListaTiendas para obtener aquellos que correspondan al IDArticuloColorTalle seleccionado
    const stockInfo = ListaTiendas.filter((item) => item.IDArticuloColorTalle === IDArticuloColorTalle && item.Stock !== 0);
    setSelectedStockInfo(stockInfo);
    setIsStockPopupOpen(true);
  };

  // Función para calcular la suma del stock para el IDArticuloColorTalle seleccionado
  const calculateTotalStock = (IDArticuloColorTalle) => {
    const stockInfo = ListaTiendas.filter((item) => item.IDArticuloColorTalle === IDArticuloColorTalle);
    const totalStock = stockInfo.reduce((sum, item) => sum + item.Stock, 0);
    return totalStock;
  };




  // Variables Ventana Popup Codigo de Barras
  // Estado para controlar si el popup del Codigo de Barras está abierto o cerrado
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newBarcode, setNewBarcode] = useState(''); // Initialize with "0" here
  const inputRef = useRef(null);

  const openModal = () => {
    setNewBarcode('');
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleBarcodeChange = (event) => {
    setNewBarcode(event.target.value.toUpperCase());
  };

  const handleSearch = () => {
    setScannedBarcode(newBarcode);
    closeModal();
  };


  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Evitar el comportamiento predeterminado (submit del formulario)
      if (newBarcode.length > 0) {
        handleSearch();
      }
    }
  };




  useEffect(() => {
    // Definir la función de manejo del evento
    const handleEvent = (event) => {
      const barcode = event.data;
      setScannedBarcode(barcode);
    };
  
    // Agregar el event listener utilizando la función de manejo del evento
    window.addEventListener('message', handleEvent);
  
    // Eliminar el event listener utilizando la misma función de manejo del evento
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);
  

  const profileBrand = useSelector((store) => store.user.profile.brand);


  /**
   * Request Prices
   */
  useEffect(() => {
    setPricesAreLoading(true);

  // Verifica si scannedBarcode no es igual a '-'
  if (scannedBarcode !== '-') {
    API.graphql(
      graphqlOperation(getPrices, {
        brand: profileBrand,
        product: scannedBarcode,
        storesfilter:
          store.Apodo === 'Todos'
            ? stores.map((store) => store.IDTienda)
            : [store.IDTienda],
        stores:
          stores.map((store) => store.IDTienda)
      })
    )
      .then((response) => {
        setPrices(response.data);
        setPricesAreLoading(false);
        setStorePage(0);

        setPreciosDetalle(response.data.getModuloPrecios.SubmoduloPreciosDetalle);
        setListaColores(response.data.getModuloPrecios.SubmoduloPreciosCombinaciones.ListaColores);
        setListaTalles(response.data.getModuloPrecios.SubmoduloPreciosCombinaciones.ListaTalles);
        setListaTiendasFiltro(response.data.getModuloPrecios.SubmoduloPreciosCombinaciones.ListaCombinacionesTiendasFiltro);
        setListaTiendas(response.data.getModuloPrecios.SubmoduloPreciosCombinaciones.ListaCombinacionesTiendas);

      })
      .catch((error) => {
        if (error === 'No current user') history.push('/login'); // TODO verify token refresh
        setPricesRequestError(true);
        setPricesAreLoading(false);
        setStorePage(0);

      });


    } 
    else 
    {
      // Establece valores vacíos o predeterminados cuando scannedBarcode es '-'
      setPrices(null);
      setPricesAreLoading(false);
      setStorePage(0);
      setPreciosDetalle('');
      setListaColores([]);
      setListaTalles([]);
      setListaTiendasFiltro([]);
      setListaTiendas([]);
    }

  }, [store, period, stores, scannedBarcode]);


  

  /**
   * change the number of bars if the with of the screen is less than the table brackpoint
   */
  useEffect(() => {
    width < tabletBreakpoint ? setStorePageSize(5) : setStorePageSize(5);
  }, [width]);

  if (pricesAreLoading) {
    return <Loader></Loader>;
  }

  if (pricesRequestError) {
    return <ErrorMessage />;
  }


 


  return (
  <PrototypeWrapper>
  <Box className="boxes">


    <Card border="#00C6FF" animation={{ delay: 400 }} px="32px">
      <CardTitle>Precios Detalle</CardTitle>
      <Box width={1} style={{ textAlign: 'center' }}>


      <Flex
        justifyContent="space-between"
      > 

      <Flex
        flexWrap="wrap"
        justifyContent="space-between"

        onClick={openModal}
        style={{cursor: 'pointer'}}
      > 
        {[
        { Titulo: 'CODIGO DE BARRAS', Valor: scannedBarcode },
        { Titulo: 'IDARTICULO', Valor: scannedBarcode == '-' ? '-' : PreciosDetalle.IDArticulo },
        { Titulo: 'ARTICULO', Valor: scannedBarcode == '-' ? '-' : PreciosDetalle.Articulo },
        { Titulo: 'STOCK', Valor: scannedBarcode == '-' ? "0" : (new Intl.NumberFormat('en-US').format(PreciosDetalle.Stock)) }].map(
        ({ Titulo, Valor }) => (
            <Highlight key={Titulo}>
              <div className="icon">
                {Titulo === 'PRECIO' ? (
                  <MoneySign />
                ) : Titulo === 'IDARTICULO' || Titulo === 'ARTICULO' ? (
                  <Ticket />
                ) : Titulo === 'STOCK' ? (
                  <BoxIcon />
                ) : Titulo === 'CODIGO DE BARRAS' ? (
                  <CouponIcon />
                ) : (
                  <MoneySign />
                )}
              </div>
              <Box width={1}>
                <div className="title">{Titulo}</div>
                <Flex className="values">
                  <div style={{ marginRight: '10px', textAlign: 'left' }}>{Valor}</div>
                </Flex>
              </Box>
            </Highlight>
          )
        )}
      </Flex>



      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
      > 
        {[
        { Titulo: 'IMAGEN', Valor: scannedBarcode == '-' ? '-' : PreciosDetalle.Imagen }].map(
        ({ Titulo, Valor }) => (

            <HighlightImage key={Titulo} image={Valor} >
            </HighlightImage>

          )
        )}
      </Flex>

      </Flex>



      <Flex
        justifyContent="space-between"
      > 
        {[
        { Titulo: 'PUBLICO', Valor: scannedBarcode == '-' ? "$0" : "$" + (new Intl.NumberFormat('en-US').format(PreciosDetalle.Precio)) },
        { Titulo: 'ACUERDO', Valor: scannedBarcode == '-' ? "$0" : "$" + (new Intl.NumberFormat('en-US').format(PreciosDetalle.Precio2)) }].map(
        ({ Titulo, Valor }) => (
            <Highlight key={Titulo}>
              <div className="icon">
                {Titulo === 'PRECIO' ? (
                  <MoneySign />
                ) : Titulo === 'IDARTICULO' || Titulo === 'ARTICULO' ? (
                  <Ticket />
                ) : Titulo === 'STOCK' ? (
                  <BoxIcon />
                ) : Titulo === 'CODIGO DE BARRAS' ? (
                  <CouponIcon />
                ) : (
                  <MoneySign />
                )}
              </div>
              <Box width={1}>
                <div className="title">{Titulo}</div>
                <Flex className="values">
                  <div style={{ marginRight: '10px' }}>{Valor}</div>
                </Flex>
              </Box>
            </Highlight>
          )
        )}
      </Flex>



      </Box>
    </Card>



    <Card border="#00C6FF" animation={{ delay: 400 }} px="32px">
      <CardTitle>Precios Combinaciones</CardTitle>
      <Box width={1} >

      <Flex alignItems="center" justifyContent="flex-start">

        <HighlightTableColumns 
        style={{backgroundColor: 'none', border: 'none' }}>
        </HighlightTableColumns>
        {/* Mostrar solo los colores correspondientes a la página actual */}
        {ListaColores.slice(startIndex, endIndex).map(
        ({ IDColor, Color, ColorBase }) => (
            <HighlightTableColumns key={IDColor} 
            style={{backgroundColor: ColorBase, border: '1px solid #2f455c;' }}>
            </HighlightTableColumns>     
          )
        )}
      </Flex>



      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(0,1).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*0 + startIndex, ListaColores.length*0 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

      {ListaTalles.slice(1,2).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*1 + startIndex, ListaColores.length*1 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

      {ListaTalles.slice(2,3).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*2 + startIndex, ListaColores.length*2 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(3,4).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*3 + startIndex, ListaColores.length*3 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(4,5).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*4 + startIndex, ListaColores.length*4 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(5,6).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*5 + startIndex, ListaColores.length*5 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(6,7).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*6 + startIndex, ListaColores.length*6 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(7,8).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*7 + startIndex, ListaColores.length*7 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(8,9).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*8 + startIndex, ListaColores.length*8 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(9,10).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*9 + startIndex, ListaColores.length*9 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(10,11).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*10 + startIndex, ListaColores.length*10 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(11,12).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*11 + startIndex, ListaColores.length*11 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(12,13).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*12 + startIndex, ListaColores.length*12 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(13,14).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*13 + startIndex, ListaColores.length*13 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(14,15).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*14 + startIndex, ListaColores.length*14 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(15,16).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*15 + startIndex, ListaColores.length*15 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(16,17).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*16 + startIndex, ListaColores.length*16 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(17,18).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*17 + startIndex, ListaColores.length*17 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(18,19).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*18 + startIndex, ListaColores.length*18 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>


      <Flex alignItems="center" justifyContent="flex-start">

        {ListaTalles.slice(19,20).map(
        ({ IDTalle, Talle, TalleBase }) => (
          <HighlightTableRows key={IDTalle} 
          style={{border: 'none', cursor: 'auto' }}>

          <Box width={1}>
            <div className="title">{TalleBase}</div>
          </Box>
          </HighlightTableRows>
          )
        )}


        {ListaTiendasFiltro.slice(ListaColores.length*19 + startIndex, ListaColores.length*19 + endIndex).map(
        ({ IDArticuloColorTalle, IDArticulo, IDColor, IDTalle, Articulo, Color, Talle, ColorBase, TalleBase, Stock }) => (
          <HighlightTableRows key={IDArticuloColorTalle} 
          style={{border: '1px solid #2f455c', cursor: 'pointer', backgroundColor: IDArticuloColorTalle === PreciosDetalle.IDArticuloColorTalle ? '#29eaec29' : 'none' }}
          onClick={() => handleOpenStockPopup(IDArticuloColorTalle)}>

          <Box width={1}>
            <div className="title">{Stock == 0 ? '-' : Stock}</div>
          </Box>
          </HighlightTableRows>
          )
        )}
      </Flex>




      <Flex alignItems="center" justifyContent="center">
            {/* Botón ChevronLeft */}
            <ChevronLeft
              onClick={() =>
                setStorePage((storePage) => (storePage - 1 >= 0 ? storePage - 1 : storePage))
              }
              style={{
                stroke: 'rgba(255,255,255,0.28)',
                height: 34,
                width: 34,
                cursor: 'pointer',
              }}
            />
            {/* Muestra el número de página actual y la cantidad total de páginas */}
            <div
              style={{
                color: 'rgba(255,255,255,0.28)',
              }}
            >
              Página {storePage + 1} de {Math.ceil(ListaColores.length / storePageSize)}
            </div>
            {/* Botón ChevronRight */}
            <ChevronRight
              onClick={() =>
                setStorePage((storePage) =>
                  storePage * storePageSize + storePageSize < ListaColores.length
                    ? storePage + 1
                    : storePage
                )
              }
              style={{
                stroke: 'rgba(255,255,255,0.28)',
                height: 34,
                width: 34,
                cursor: 'pointer',
              }}
            />
      </Flex>




      </Box>
    </Card>



  </Box>



  <Modal
      isOpen={isStockPopupOpen}
      onRequestClose={() => setIsStockPopupOpen(false)}
      // Estilos del Modal con estilos en línea
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9998,
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          backgroundColor: '#36516C',
          color: '#FFF', // Color blanco
          fontSize: '16px',
          border: '1px solid #2f455c',
          borderRadius: '8px',
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)',
          padding: '20px',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
          fontFamily: 'Boston', // Fuente Boston
        },
      }}
      ariaHideApp={false}
    >
      {/* Contenido del Modal del stock de tiendas */}
      {selectedStockInfo && selectedStockInfo.length > 0 ? (
        <div>

          {/* Botón fijo en la esquina superior izquierda */}
          <div style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'none' }}>
          <button
            onClick={() => setIsStockPopupOpen(false)}
            style={{
              backgroundColor: '#36516C',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              fontSize: '16px',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}>
            X
          </button>
    </div>

          {/* Mostrar los datos del IDArticuloColorTalle */}
          <div>
            <Text style={{cursor: 'default'}}>Articulo: {selectedStockInfo[0].Articulo}</Text>
            <Text style={{cursor: 'default'}}>Color: {selectedStockInfo[0].Color}</Text>
            <Text style={{cursor: 'default'}}>Talle: {selectedStockInfo[0].Talle}</Text>
            <hr style={{ borderBottom: '1px solid #748599' }}/>
          </div>


          {/* Mostrar la tabla con sucursales y stock */}
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th style={{ cursor: 'default', borderBottom: '2px solid #748599' }}>TIENDAS</th>
                <th style={{ cursor: 'default', borderBottom: '2px solid #748599' }}>STOCK</th>
              </tr>
            </thead>
            <tbody>
              {selectedStockInfo.map(({ IDArticuloColorTalle, Tienda, Stock }) => (
                <tr key={IDArticuloColorTalle}>
                  <td style={{ cursor: 'default', borderBottom: '1px solid #748599' }}>{Tienda}</td>
                  <td style={{ cursor: 'default', borderBottom: '1px solid #748599' }}>{Stock}</td>
                </tr>
              ))}

              {/* Agregar la fila del total del stock al final de la tabla */}
              <tr>
                <td style={{ cursor: 'default', borderBottom: '2px solid #748599' }}>TOTAL</td>
                <td style={{ cursor: 'default', borderBottom: '2px solid #748599' }}>{calculateTotalStock(selectedStockInfo[0].IDArticuloColorTalle)}</td>
              </tr>
              
            </tbody>
          </table>
        </div>
      ) : (
        <div>No hay stock en Tiendas</div>
      )}
  </Modal>



  <Modal 
      isOpen={modalIsOpen}
      onAfterOpen={focusInput} // Aquí se enfoca el input después de abrir el modal
      onRequestClose={closeModal}
      // Estilos del Modal con estilos en línea
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9998,
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          backgroundColor: '#36516C',
          color: '#FFF', // Color blanco
          fontSize: '16px',
          border: '1px solid #2f455c',
          borderRadius: '8px',
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)',
          padding: '20px',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
          fontFamily: 'Boston', // Fuente Boston
        },
      }}
      ariaHideApp={false}
    >

    {/* Botón fijo en la esquina superior izquierda */}
    <div style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'none' }}>
      <button
        onClick={closeModal}
        style={{
          backgroundColor: '#36516C',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          fontSize: '16px',
          fontWeight: 'bold',
          cursor: 'pointer'
        }}>
      X
      </button>

    </div>


      <Flex
        justifyContent="center"
        style={{marginTop: '10px', marginBottom: '10px'}}
      > 

      <Text style={{cursor: 'default'}}>Escriba Código de Barras</Text>

      </Flex>

      <Flex
        justifyContent="center"
        style={{marginBottom: '15px'}}
      > 
      <input
        type="text"
        value={newBarcode}
        onChange={handleBarcodeChange}
        onKeyDown={handleKeyDown} // Controlador para la tecla Enter
        ref={inputRef}
      />

    </Flex>


      <Flex
        justifyContent="center"
        style={{marginBottom: '10px'}}
      > 
      <button 
        style={{
          backgroundColor: '#36516C',
          color: 'white',
          border: '1px solid #FFFFFF',
          borderRadius: '5px',
          marginLeft: '5px',
          fontSize: '16px',
          fontWeight: 'bold',
          padding: '6px 6px 6px 6px',
          cursor: 'pointer'
        }}
      onClick={handleSearch} disabled={newBarcode.length === 0}> Buscar</button>
    </Flex>



  </Modal>



  </PrototypeWrapper>
  );
} 

export default Prices;

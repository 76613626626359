import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../icons/indicator.svg';

const Wrapper = styled.div`
  position: absolute;
  border-radius: 20px 0 0 20px;
  width: 54px;
  height: 41px;
  background: ${props => props.theme.bg};
  right: 0;
  top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  color: ${props => props.color};
  z-index: 3;
`;

const Indicator = ({ status }) => (
  <Wrapper color={status >= 0 ? '#08EF08' : '#FF5B00'}>
    <ArrowIcon
      style={{ transform: status < 0 && 'rotate(180deg)' }}
      width="21"
    />
  </Wrapper>
);

export default Indicator;

import styled from 'styled-components';

const CardTitle = styled.div`
  font-size: 1rem;
  color: #748599;
  display: inline-flex;
  align-items: center;
  padding: 10px;

  > svg {
    position: relative;
    top: -4px;
    margin-left: 1px;
  }

  span.icon {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.07);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
`;

export default CardTitle;

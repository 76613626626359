import React from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';
import { format as d3format } from 'd3-format';

import { Flex, Box, Text } from '../components/Base';
import PrototypeWrapper from './Wrapper';
import Card from '../components/Card';
import CardTitle from './components/CardTitle';
import Highlight from './components/Highlight';
import { TableList, TableItem } from './components/TableList';
import ProgressBar from './components/ProgressBar';
import StockBalance from './components/StockBalance';

import { ReactComponent as BoxIcon } from '../icons/box.svg';
import { ReactComponent as UnitKg } from '../icons/unit-kg.svg';
import { ReactComponent as CatIce1Icon } from '../icons/cat_ice_1.svg';
import { ReactComponent as CatIce4Icon } from '../icons/cat_ice_4.svg';
import { ReactComponent as CatIce5Icon } from '../icons/cat_ice_5.svg';
import { ReactComponent as CatSundaeIcon } from '../icons/cat_sundae.svg';

const data2 = [
  {
    name: 'Palitos',
    uv: 4000,
    pv: 2400,
  },
  {
    name: 'Cucuruchos',
    uv: 3000,
    pv: 1398,
  },
  {
    name: 'Helado común',
    uv: 12000,
    pv: 9800,
  },
  {
    name: 'Especiales',
    uv: 4000,
    pv: 3908,
  },
  {
    name: 'Familiar',
    uv: 5891,
    pv: 4800,
  },
  {
    name: 'Tortas',
    uv: 4000,
    pv: 3800,
  },
  {
    name: 'Postres',
    uv: 4500,
    pv: 4300,
  },
  {
    name: 'Semifríos',
    uv: 7000,
    pv: 3900,
  },
  {
    name: 'Cafetería',
    uv: 8000,
    pv: 3900,
  },
  {
    name: 'Pizzas',
    uv: 5400,
    pv: 5100,
  },
].sort((a, b) => b.pv - a.pv);

const legends = {
  uv: 'Stock disponible',
  pv: 'Ventas',
};

const Stock = () => (
  <PrototypeWrapper>
    <Box className="boxes">
      <Card border="#F0A82F" animation={{ delay: 0 }}>
        <CardTitle>
          <span className="icon">
            <BoxIcon style={{ height: 11, color: '#F0A82F' }} />
          </span>
          Stock Valorizado
        </CardTitle>
        <Box width={1} mt={3}>
          <Text fontSize={64} lineHeight={1} textAlign="center">
            $736.431
          </Text>
          <Flex
            justifyContent="space-around"
            mt="16px"
            width={[0.8, 0.8, 0.8, 0.8, 0.6]}
            mx="auto"
          >
            <Highlight color="#F9D423">
              <div className="icon">
                <UnitKg />
              </div>
              <Box width={1}>
                <div className="title">Kilos</div>
                <Flex className="values">
                  <div>10.500</div>
                </Flex>
              </Box>
            </Highlight>
            <Highlight color="#F9D423">
              <div className="icon">
                <BoxIcon />
              </div>
              <Box width={1}>
                <div className="title">Unidades</div>
                <Flex className="values">
                  <div>9.000</div>
                </Flex>
              </Box>
            </Highlight>
          </Flex>
        </Box>
      </Card>
      <Card border="#F0A82F" animation={{ delay: 200 }}>
        <CardTitle>
          <span className="icon">
            <BoxIcon style={{ height: 11, color: '#F0A82F' }} />
          </span>
          Grupos Críticos
        </CardTitle>
        <Box width={0.95} height="100%" p="44px 0 16px 20px">
          <ResponsiveContainer style={{ marginTop: 56 }}>
            <BarChart
              data={data2}
              style={{
                borderRadius: 10,
                background: '#3C5C7A',
                boxShadow: '1px 1px 9px 2px rgba(0, 0, 0, 0.09)',
              }}
              // margin={{ top: 20, right: 10, bottom: 0, left: -16 }}
              margin={{ top: -32, right: 10, bottom: 0, left: -16 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#47D3CE" stopOpacity={1} />
                  <stop offset="110%" stopColor="#3AA57A" stopOpacity={1} />
                </linearGradient>
              </defs>
              <CartesianGrid
                stroke="rgba(0,0,0,0.13)"
                strokeWidth={0.5}
                style={{ padding: '0.8rem' }}
              />
              <Legend
                verticalAlign="top"
                align="right"
                height={48}
                iconType="circle"
                formatter={(value, entry) => (
                  <Text as="span" color="#8699AD" fontSize={14}>
                    {legends[value]}
                  </Text>
                )}
              />
              <XAxis
                dataKey="name"
                stroke="rgba(0,0,0,0.04)"
                interval={0}
                width={30}
                tick={({ x, y, payload }) => (
                  <g transform={`translate(${x}, ${y + 5})`}>
                    <text
                      x={0}
                      y={0}
                      dy={3}
                      textAnchor="middle"
                      fill="#8699AD"
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {payload.value.split(' ').map((t, idx) => (
                        <tspan dy={`${idx}em`} x={0}>
                          {t}
                        </tspan>
                      ))}
                    </text>
                  </g>
                )}
              />
              <YAxis
                stroke="rgba(0,0,0,0.04)"
                tick={({ x, y, payload }) => (
                  <g transform={`translate(${x},${y})`}>
                    <text
                      x={0}
                      y={0}
                      dy={3}
                      textAnchor="end"
                      fill="#8699AD"
                      style={{
                        textTransform: 'uppercase',
                        fontSize: 11,
                      }}
                    >
                      {d3format('.2s')(payload.value)}
                    </text>
                  </g>
                )}
              />
              <Bar
                dataKey="pv"
                radius={5}
                barSize={20}
                stackId="a"
                fill="url(#colorUv)"
              >
                {data2.map((entry, index, ...args) => {
                  console.log({ entry, index, args });
                  return <Cell key={`cell-${index}`} fill="url(#colorUv)" />;
                })}
              </Bar>
              <Bar
                dataKey="uv"
                stackId="a"
                fill="#4D7691"
                radius={[5, 5, 0, 0]}
              >
                {data2.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    // style={{ transform: 'translate(0, 4px)' }}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Card>
      <Card border="#F0A82F" animation={{ delay: 400 }}>
        <CardTitle>
          <span className="icon">
            <BoxIcon style={{ height: 11, color: '#F0A82F' }} />
          </span>
          Eficiencia Stock
        </CardTitle>
        <Flex justifyContent="center" alignItems="center" width={0.85}>
          <Text fontSize={40} lineHeight={1} mr={64}>
            Óptimo
          </Text>
          <StockBalance value={13} />
        </Flex>
      </Card>
      <Card border="#F0A82F" animation={{ delay: 600 }}>
        <CardTitle>
          <span className="icon">
            <BoxIcon style={{ height: 11, color: '#F0A82F' }} />
          </span>
          Productos Críticos
        </CardTitle>
        <TableList mt={24} width={0.97}>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#F9D423">
                <CatIce1Icon height={16} />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Palito Bombón
              </Text>
            </Flex>
            <ProgressBar
              width={[1, '75px', '75px', '75px', '75px', 0.5]}
              progress={102}
              noColor
              noLabel
            />
            <Text color="#5A93A7" width="136px" fontSize={12}>
              Quiebre en
              <Text as="span" color="white" fontSize={14} ml={'8px'}>
                5 días
              </Text>
            </Text>
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#F9D423">
                <CatIce4Icon />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Cucuruchos
              </Text>
            </Flex>
            <ProgressBar
              width={[1, '75px', '75px', '75px', '75px', 0.5]}
              progress={85}
              noColor
              noLabel
            />
            <Text color="#5A93A7" width="136px" fontSize={12}>
              Quiebre en
              <Text as="span" color="white" fontSize={14} ml={'8px'}>
                10 días
              </Text>
            </Text>
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#F9D423">
                <CatIce5Icon />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Familiar
              </Text>
            </Flex>
            <ProgressBar
              width={[1, '75px', '75px', '75px', '75px', 0.5]}
              progress={66}
              noColor
              noLabel
            />
            <Text color="#5A93A7" width="136px" fontSize={12}>
              Quiebre en
              <Text as="span" color="white" fontSize={14} ml={'8px'}>
                13 días
              </Text>
            </Text>
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Text className="icon" color="#F9D423">
                <CatSundaeIcon />
              </Text>
              <Text
                ml={2}
                width="120px"
                fontSize={12}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="#99B0C1"
              >
                Tentación Cadbury
              </Text>
            </Flex>
            <ProgressBar
              width={[1, '75px', '75px', '75px', '75px', 0.5]}
              progress={66}
              noColor
              noLabel
            />
            <Text color="#5A93A7" width="136px" fontSize={12}>
              Quiebre en
              <Text as="span" color="white" fontSize={14} ml={'8px'}>
                15 días
              </Text>
            </Text>
          </TableItem>
        </TableList>
      </Card>
    </Box>
  </PrototypeWrapper>
);

export default Stock;

import styled from 'styled-components';
import { Flex } from '../../components/Base';

const HighlightImage = styled(Flex)`
  border-radius: 10px;
  color: white;
  font-size: 16px;
  line-height: 1;
  margin: 5px;
  padding: 6px 8px 6px 6px;
  align-items: center;
  width: 100%;
  border: 1px solid #2f455c;
  min-width: 140px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; 
`;

export default HighlightImage;
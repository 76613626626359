import { combineReducers } from 'redux';
import {
  FEEDBACK_SENT,
  FEEDBACK_SHOW,
  THEME_SET_MODE,
  USER_SET,
  STORES_SET,
  FILTERS_SET,
  FILTERS_RESET,
  PROFILE_SET
} from '../actions';

const FiltersReducer = (
  state = { period: { label: 'Dia' }, store: { Apodo: 'Todos', share: 0 } },
  action
) => {
  switch (action.type) {
    case FILTERS_SET:
      return { ...state, ...action.filter };
    case FILTERS_RESET:
      return { period: { label: 'Dia' }, store: { Apodo: 'Todos', share: 0 } };
    default:
      return state;
  }
};

const UserReducer = (state = { profile: { name: '' }, stores: [] }, action) => {
  switch (action.type) {
    case USER_SET:
      localStorage.setItem('username', action.user.name);
      localStorage.setItem('phone', action.user.email);
      window.gtag('event', 'login', action);
      return { ...state, profile: action.user };
    case STORES_SET:
      return { ...state, stores: action.stores };
    case PROFILE_SET: 
      return { ...state, profile: action.profile};
    default:
      return state;
  }
};

const ThemeReducer = (state = { mode: 'dark' }, action) => {
  switch (action.type) {
    case THEME_SET_MODE:
      return { ...state, mode: action.mode };
    default:
      return state;
  }
};

const FeedbackReducer = (state = { opened: false, sent: false }, action) => {
  switch (action.type) {
    case FEEDBACK_SHOW:
      return { ...state, opened: action.opened };
    case FEEDBACK_SENT:
      return { ...state, sent: true };
    default:
      return state;
  }
};

const reducers = combineReducers({
  feedback: FeedbackReducer,
  theme: ThemeReducer,
  user: UserReducer,
  filters: FiltersReducer,
});

export default reducers;

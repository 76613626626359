// @ts-check
import { Auth } from 'aws-amplify';

import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ children, ...rest }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setIsLoggedIn(true);
        console.log('user: ', user);
      })
      .catch(() => {
        if (isLoggedIn) setIsLoggedIn(false);
      })
      .finally(() => {
        setIsloading(false);
      });
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          React.cloneElement(children, props)
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

// @ts-check

import React from 'react';
import styled from 'styled-components';
import { Box } from '../components/Base';
import Filters from './components/Filters';
import { tabletBreakpoint } from '../utils/breakpoints';

const Container = styled.div`
  max-width: 1500px;
  padding: 10px;
  margin: 0 auto;
  display: flex;

  .boxes {
    display: grid;
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    grid-gap: 10px;
    padding: 10px 0;

    a {
      display: contents;
      /* width: 100%; */
    }

    @media only screen and (max-width: ${tabletBreakpoint}px) {
      grid-template-columns: repeat(1, minmax(250px, 1fr));
    }
  }
`;

export default function Prototype({ children }) {
  return (
    <>
      <Container>
        <Box width={1}>
          <Filters />
          {children}
          {/* <FooterToolbar /> */}
        </Box>
      </Container>
    </>
  );
}

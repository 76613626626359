import React from 'react';
import styled from 'styled-components';
import { currency } from '../../utils/currency';
import { percentage } from '../../utils/percentage';

const BoxStyle = styled('span')`
  display: inline-block;
  padding: 5px;
  background: #2f4a60;
  letter-spacing: 0.5px;
  border-radius: 5px;
`;

export const SalesTooltip = ({ payload }) => {
  const before = payload[0];
  const current = payload[1];

  if (before && current && (current.value !== 0 || before.value !== 0)) {
    const currentPorcentage = current.value / before.value - 1;
    return (
      <BoxStyle>
        <span>{currency(current.value)}</span>
        <span
          style={{
            color: currentPorcentage < 0 ? '#fc5c1f' : '#00ff00',
            paddingLeft: '10px',
            fontSize: '12px',
          }}
        >
          {percentage(currentPorcentage)}
        </span>
        <div
          style={{
            color: before.color,
          }}
        >
          {currency(before.value)}
        </div>
      </BoxStyle>
    );
  }
  return null;
};

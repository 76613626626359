import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { space, color } from 'styled-system';
import { Transition } from 'react-spring/renderprops';

// import stores from '../../data/stores';
import { setFilter } from '../../actions';

import { ReactComponent as Day } from '../../icons/day.svg';
import { ReactComponent as Week } from '../../icons/week.svg';
import { ReactComponent as Month } from '../../icons/month.svg';
import { ReactComponent as Year } from '../../icons/year.svg';
import { ReactComponent as Store } from '../../icons/store.svg';

const FilterOptions = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  margin-top: 59px;
  padding-top: 19px;
  width: 100%;
  background: ${(props) => props.theme.bg};
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-top: 0;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Boston';
  z-index: 1;

  button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
    appearance: none;
    background: none;
    border: 0;
    font-size: 1rem;
    cursor: pointer;
    outline: 0;
    text-align: center;
    margin: 10px auto;

    div {
      line-height: 36px;
      white-space: nowrap;
    }

    &::after {
      content: '';
      border-radius: 10px;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    svg {
      width: 20px;
      margin-right: 8px;
      top: -1px;
      position: relative;
    }

    &.active,
    &:hover {
      color: ${(props) => props.theme.colors.primary};
      background: rgba(55, 55, 55, 0.06);

      &::after {
        background: #29eaec29;
      }
    }
  }
`;

const FiltersContainer = styled.div`
  ${space}
  ${color}
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  .buttons {
    display: flex;
    width: 100%;

    button {
      color: ${(props) => props.theme.colors.primary};
      background: ${(props) => props.theme.bg};
      border: 2px solid ${(props) => props.theme.colors.primary};
      border-radius: 8px;
      width: 100%;
      height: 80px;
      position: relative;
      z-index: 2;
      appearance: none;
      outline: 0;
      font-family: 'Montserrat';
      font-size: 32px;
      cursor: pointer;
      margin: 0 auto;

      &:active,
      &:focus {
        background: ${(props) => props.theme.bg};
      }
    }
  }

  hr {
    width: 100%;
    position: absolute;
    top: 109px;
    left: 0;
    height: 2px;
    border: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  @media screen and (min-width: 1138px) and (max-height: 712px) {
    height: 100px;
    margin-top: 12px;
    hr {
      display: none;
    }
  }
`;

function Filters() {
  const dispatch = useDispatch();
  const { stores } = useSelector((store) => store.user);
  const { store, period } = useSelector((store) => store.filters);
  const [showOptions, setOptions] = useState(0);
  const storeWithtotal = [{ Apodo: 'Todos' }, ...stores];

  useEffect(() => {
    setOptions(0);
  }, [store, period]);

  const setPeriod = (period) => {
    dispatch(setFilter({ period }));
  };

  const setStore = (store) => {
    dispatch(setFilter({ store }));
  };

  const profileName = useSelector((store) => store.user.profile.name);

  if (profileName === 'Vendedor') {
    return (
      <FiltersContainer>
        <div className="buttons">

          <button onClick={() => setOptions(showOptions === 2 ? 0 : 2)}>
            {store ? store.Apodo : 'Todos'}
          </button>
        </div>
        <Transition
          items={showOptions}
          from={{ transform: 'translate3d(0,-40px, 0)', opacity: 0 }}
          enter={{ transform: 'translate3d(0,0px, 0)', opacity: 1 }}
          leave={{ transform: 'translate3d(0,-40px, 0)', opacity: 0 }}
        >
          {(showOptions) =>
            showOptions &&
            ((styles) => {
              if (showOptions === 2) {
                return (
                  <FilterOptions style={styles}>
                    {storeWithtotal.map((s, idx) => (
                      <button
                        key={s.Apodo}
                        // style={{ width: `${100 / storeWithtotal.length}%` }}
                        className={store.Apodo === s.Apodo ? 'active' : undefined}
                        onClick={() =>
                          store && store.Apodo === s.Apodo ? null : setStore(s)
                        }
                      >
                        <Store />
                        <div>{s.Apodo}</div>
                      </button>
                    ))}
                  </FilterOptions>
                );
              }
            })
          }
        </Transition>
      </FiltersContainer>
    );
  }

  else {
    return (
      <FiltersContainer>
        <div className="buttons">
          <button
            onClick={() => setOptions(showOptions === 1 ? 0 : 1)}
            style={{ marginRight: '1%' }}
          >
            {period.label === 'Dia'
              ? 'Hoy'
              : period.label === 'Semana'
              ? 'Semana'
              : period.label === 'Mes'
              ? 'Mes'
              : period.label === 'Anio'
              ? 'Año'
              : null}
          </button>
          <button onClick={() => setOptions(showOptions === 2 ? 0 : 2)}>
            {store ? store.Apodo : 'Todos'}
          </button>
        </div>
        <Transition
          items={showOptions}
          from={{ transform: 'translate3d(0,-40px, 0)', opacity: 0 }}
          enter={{ transform: 'translate3d(0,0px, 0)', opacity: 1 }}
          leave={{ transform: 'translate3d(0,-40px, 0)', opacity: 0 }}
        >
          {(showOptions) =>
            showOptions &&
            ((styles) => {
              if (showOptions === 2) {
                return (
                  <FilterOptions style={styles}>
                    {storeWithtotal.map((s, idx) => (
                      <button
                        key={s.Apodo}
                        // style={{ width: `${100 / storeWithtotal.length}%` }}
                        className={store.Apodo === s.Apodo ? 'active' : undefined}
                        onClick={() =>
                          store && store.Apodo === s.Apodo ? null : setStore(s)
                        }
                      >
                        <Store />
                        <div>{s.Apodo}</div>
                      </button>
                    ))}
                  </FilterOptions>
                );
              }
              if (showOptions === 1) {
                return (
                  <FilterOptions style={styles}>
                    <button
                      className={period.label === 'Dia' ? 'active' : null}
                      onClick={() => setPeriod({ label: 'Dia', mult: 1 })}
                    >
                      <Day />
                      <span>Hoy</span>
                    </button>
                    <button
                      className={period.label === 'Semana' ? 'active' : null}
                      onClick={() =>
                        setPeriod({
                          label: 'Semana',
                          mult: 11.50156,
                        })
                      }
                    >
                      <Week />
                      <span>Semana</span>
                    </button>
                    <button
                      className={period.label === 'Mes' ? 'active' : null}
                      onClick={() =>
                        setPeriod({
                          label: 'Mes',
                          mult: 11.50156 * 3.0625,
                        })
                      }
                    >
                      <Month />
                      <span>Mes</span>
                    </button>
                    <button
                      className={period.label === 'Anio' ? 'active' : null}
                      onClick={() =>
                        setPeriod({
                          label: 'Anio',
                          mult: 52 * 0.65 * 11.50156,
                        })
                      }
                    >
                      <Year />
                      <span>Año</span>
                    </button>
                  </FilterOptions>
                );
              }
            })
          }
        </Transition>
      </FiltersContainer>
    );
  }

}

export default Filters;

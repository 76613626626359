const setSession = (date) => +new Date(date.getTime() + 5 * 60000);

export default {
  isAuthenticated() {
    return !!localStorage.getItem('username');
  },
  authenticate(cb) {
    localStorage.setItem('login', setSession(new Date()));
    setTimeout(cb, 100);
  },
  signout(cb) {
    setTimeout(cb, 100);
  },
};

// @ts-check

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import './App.css';
import Login from './Login';
import fakeAuth from './fakeAuth';
// import DashboardJSON from "./data/dashboard";
import Feedback from './Feedback';
import Dashboard from './Prototype/Dashboard';
import Sales from './Prototype/Sales';
import Goals from './Prototype/Goals';
import Stock from './Prototype/Stock';
import Demand from './Prototype/Demand';
import { getTheme } from './theme';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { Hub } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import MainPage from './Prototype/MainPage';
import PrivateRoute from './Prototype/PrivateRoute';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists

  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(
    createHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URI,
    })
  ),
});

const GlobalStyle = createGlobalStyle`
  :root {
    --bg-color: ${(props) => props.theme.bg};
  }

  body {
    color: ${(props) => (props.whiteColor ? 'white' : 'black')};
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: 600;
  }
`;

function App() {
  const mode = useSelector((store) => store.theme.mode);
  const history = useHistory();

  /**
   * Go to login if the token refresh fail
   */
  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'tokenRefresh_failure') {
        history.push('/login');
      }
    });
  }, [history]);

  return (
    <ThemeProvider theme={getTheme(mode)}>
      <ApolloProvider client={client}>
        <GlobalStyle />
        <BrowserRouter>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <PrivateRoute path="/">
              <MainPage />
            </PrivateRoute>
          </Switch>
          <Feedback />
        </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
/*
 * action types
 */

export const FEEDBACK_SHOW = 'FEEDBACK_SHOW';
export const FEEDBACK_SENT = 'FEEDBACK_SENT';
export const THEME_SET_MODE = 'THEME_MODE_CHANGE';
export const USER_SET = 'USER_SET';
export const FILTERS_SET = 'FILTERS_SET';
export const FILTERS_RESET = 'FILTERS_RESET';
export const STORES_SET = 'STORES_SET';
export const PROFILE_SET = 'PROFILE_SET'

/*
 * action creators
 */

export function showFeedback(opened) {
  return { type: FEEDBACK_SHOW, opened };
}

export function sendFeedback(feedback) {
  return { type: FEEDBACK_SHOW, feedback };
}

export function changeThemeMode(mode) {
  return { type: THEME_SET_MODE, mode };
}

export function setUser(user) {
  return { type: USER_SET, user };
}

export function setFilter(filter) {
  return { type: FILTERS_SET, filter };
}

export function resetFilters() {
  return { type: FILTERS_RESET };
}

/**
 * Add the stores that the user has
 *
 * @param {*} stores an Object like
 * {
 *   Apodo: "Gestion"
 *   Ciudad: "26120"
 *   Empresa: "Ariati SRL"
 *   IDEmpresa: "E6"
 *   IDTienda: "T4000"
 *   Pais: "1"
 *   Provincia: "6"
 * }
 */

export function setStores({ stores }) {
  return { type: STORES_SET, stores };
}


export function setProfile(profile) {
  return { type: PROFILE_SET, profile }
}
import styles from './ErrorMessage.module.css';

// @ts-check

export default function ErrorMessage() {
  console.log(styles);
  return (
    <div>
      <svg
        id="OBJECTS"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 350"
        width="300"
        style={{
          margin: '10px auto',
          display: 'block',
        }}
      >
        <g className={styles['cls-1']} style={{ opacity: 0.22 }}>
          <path
            fill="#e8f9ff"
            className={styles['cls-2']}
            d="M267.622,93.955C219.408,109.349,66.688,33.442,78.64,137.126c1.868,15.416,11.8,37.193,3.412,51.586-6.077,10.779-17.216,17.1-27.974,21.287-28.105,11.425-38.569,32.868-31.452,63.474,19.038,82.469,122.673,62.912,177.079,38.458,38.691-18.2,84.323-21.322,124.387-5.928,31.388,12.534,50.954,38.828,87.793,31.125,32.67-6.556,37.6-37.4,28.06-66.113-5.42-17.764-9.311-25.27,1.439-42.417,9.058-17.029,25-29.933,26.23-49.884.269-21.608-9.661-43.383-28.877-51.567-15.722-6.7-29.973-3.993-45.526-8.11-34.6-9.723-56.827-63.474-99.743-44.988-10.7,5.052-16.722,16.691-28.382,20.081"
          />
        </g>
        <g className={styles['cls-3']} style={{ opacity: 0.3 }}>
          <path
            className={styles['cls-2']}
            fill="#e8f9ff"
            d="M250.14,86.551c4.36,0,4.367-6.776,0-6.776s-4.367,6.776,0,6.776Z"
          />
          <path
            className={styles['cls-2']}
            fill="#e8f9ff"
            d="M49.419,190.723c8.719,0,8.733-13.551,0-13.551s-8.734,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            fill="#e8f9ff"
            d="M456.79,244.756c8.719,0,8.733-13.551,0-13.551s-8.734,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M227.273,83.163c8.719,0,8.734-13.551,0-13.551-8.719,0-8.734,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M470.341,223.245c11.335,0,11.353-17.617,0-17.617-11.336,0-11.354,17.617,0,17.617Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M26.552,213.081c11.335,0,11.354-17.616,0-17.616-11.335,0-11.354,17.616,0,17.616Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M402.586,107.216c11.336,0,11.354-17.616,0-17.616-11.335,0-11.353,17.616,0,17.616Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M246.752,72.153c13.079,0,13.1-20.326,0-20.326-13.079,0-13.1,20.326,0,20.326Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M477.625,239.166c6.539,0,6.55-10.163,0-10.163s-6.551,10.163,0,10.163Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M24.18,184.794c6.54,0,6.551-10.163,0-10.163s-6.55,10.163,0,10.163Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M423.76,117.04c6.539,0,6.55-10.163,0-10.163s-6.551,10.163,0,10.163Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M422.066,98.408c4.359,0,4.367-6.776,0-6.776s-4.367,6.776,0,6.776Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M78.893,86.719c8.719,0,8.733-13.551,0-13.551s-8.734,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M464.412,320.3c8.719,0,8.734-13.551,0-13.551-8.719,0-8.734,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M24.011,326.231c8.72,0,8.734-13.551,0-13.551s-8.733,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M99.219,76.556c4.359,0,4.367-6.775,0-6.775s-4.367,6.775,0,6.775Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M465.259,293.2c4.36,0,4.367-6.775,0-6.775s-4.367,6.775,0,6.775Z"
          />
          <path
            className={styles['cls-2']}
            fill={'#e8f9ff'}
            d="M44.337,334.7c4.36,0,4.367-6.776,0-6.776s-4.367,6.776,0,6.776Z"
          />
        </g>
        <path
          className={styles['cls-4']}
          fill={'#012e3f'}
          d="M141.058,220.624c-4.564,1.612-7.577,1.921-9.916-2.7-1.8-3.554-3-7.224-5.269-10.552-4.912-7.212-9.67-7.7-17.537-6.668-7.807,1.019-18.458-.975-19.643-10-.845-6.435-.894-12.352-3.711-18.372-1.388-2.966-5.768-.384-4.388,2.565,3.126,6.68,1.759,13.952,4.047,20.756,2.32,6.9,10.048,9.129,16.545,9.905,4.909.585,9.206-.6,14.02-.593,5.168.006,8.7,8.872,10.336,12.765,3.415,8.115,8.253,10.837,16.867,7.794,3.068-1.083,1.747-5.994-1.351-4.9Z"
        />
        <path
          className={styles['cls-4']}
          fill={'#012e3f'}
          d="M87.357,165.474a15.194,15.194,0,0,1-5.595,4.352c-2.342,1.18-.283,4.687,2.052,3.511a19.547,19.547,0,0,0,7.053-5.811,2.033,2.033,0,0,0-.729-2.781,2.082,2.082,0,0,0-2.781.729Z"
        />
        <path
          className={styles['cls-4']}
          fill={'#012e3f'}
          d="M80.078,160.064c.011,4.3.638,8.573.677,12.873.024,2.62,4.089,2.623,4.065,0-.039-4.3-.666-8.575-.677-12.873-.007-2.622-4.072-2.622-4.065,0Z"
        />
        <path
          className={styles['cls-4']}
          fill={'#012e3f'}
          d="M73.221,164.211c2.72,3.4,5.482,6.792,9.218,9.126,2.231,1.392,4.27-2.126,2.052-3.511-3.411-2.13-5.915-5.389-8.4-8.489-1.638-2.047-4.5.849-2.874,2.874Z"
        />
        <path
          className={styles['cls-4']}
          fill={'#012e3f'}
          d="M69.833,170.308a13.219,13.219,0,0,0,13.5,3.234c2.5-.764,1.433-4.689-1.08-3.921a9.1,9.1,0,0,1-9.541-2.187c-1.9-1.8-4.782,1.07-2.874,2.874Z"
        />
        <path
          className={styles['cls-4']}
          fill={'#012e3f'}
          d="M348.892,233.237c.183-6.64,2.4-8.856,8.813-9.378,4.165-.34,8.39-.283,12.509-1.05,6.287-1.171,6.171-9.843,8.6-14.318,3.715-6.836,13.7-6.11,20.137-8.129,7.1-2.225,9.821-8.232,10.239-15.23.2-3.274-4.886-3.256-5.081,0-.623,10.41-8.258,10.772-16.643,12.5-7.391,1.521-12.4,5.572-14.966,12.7-.805,2.229-1,6.877-3.64,7.585-4.3,1.153-9.242.626-13.663,1.105-8.309.9-11.172,6.339-11.389,14.223-.091,3.279,4.991,3.27,5.081,0Z"
        />
        <path
          className={styles['cls-4']}
          fill={'#012e3f'}
          d="M397.239,178.9a16.065,16.065,0,0,0,8.874,8.873,2.033,2.033,0,0,0,2.5-1.42,2.082,2.082,0,0,0-1.419-2.5,11.475,11.475,0,0,1-6.035-6.032,2.082,2.082,0,0,0-2.5-1.421,2.035,2.035,0,0,0-1.42,2.5Z"
        />
        <path
          className={styles['cls-4']}
          fill={'#012e3f'}
          d="M403.264,173.614v10.164c0,2.621,4.066,2.621,4.066,0V173.614c0-2.621-4.066-2.621-4.066,0Z"
        />
        <path
          className={styles['cls-4']}
          fill={'#012e3f'}
          d="M410.113,174.429a18.769,18.769,0,0,1-6.254,8.589c-2.032,1.658.863,4.516,2.875,2.874a22.567,22.567,0,0,0,7.3-10.383c.861-2.483-3.067-3.541-3.919-1.08Z"
        />
        <path
          className={styles['cls-4']}
          fill={'#012e3f'}
          d="M415.061,178.686a15.619,15.619,0,0,1-7.594,5.842c-2.391.98-1.346,4.915,1.08,3.92,4.051-1.66,7.646-3.939,10.024-7.709,1.4-2.225-2.116-4.263-3.51-2.053Z"
        />
        <path
          className={styles['cls-5']}
          fill={'#5ad3ff'}
          d="M373.117,306.863c0,3.555-26.614,4.587-29.49,4.587H144.458c-2.873,0-29.491-1.032-29.491-4.587l24.287-36.4c0-3.555,2.331-6.437,5.2-6.437H343.627c2.876,0,5.2,2.882,5.2,6.437Z"
        />
        <polygon
          className={styles['cls-6']}
          points="203.084 298.753 151.897 298.753 160.853 277.072 207.15 277.072 203.084 298.753"
        />
        <polygon
          className={styles['cls-6']}
          points="285.001 298.753 336.188 298.753 327.229 277.072 280.935 277.072 285.001 298.753"
        />
        <polygon
          className={styles['cls-6']}
          points="341.877 298.753 359.902 298.753 345.153 277.072 331.555 277.072 341.877 298.753"
        />
        <polygon
          className={styles['cls-6']}
          points="146.21 298.753 128.184 298.753 142.934 277.072 156.529 277.072 146.21 298.753"
        />
        <polygon
          className={styles['cls-6']}
          points="280.498 298.753 207.585 298.753 211.65 277.072 276.433 277.072 280.498 298.753"
        />
        <rect
          className={styles['cls-5']}
          fill={'#5ad3ff'}
          x="132.633"
          y="281.029"
          width="222.822"
          height="3.319"
        />
        <rect
          className={styles['cls-5']}
          fill={'#5ad3ff'}
          x="130.217"
          y="289.159"
          width="227.653"
          height="3.319"
        />
        <path
          className={styles['cls-6']}
          d="M374.48,315.3c0,2.874-.6,5.2-3.467,5.2H117.073c-2.877,0-3.469-2.329-3.469-5.2v-4.462c0-2.874.592-5.2,3.469-5.2h253.94c2.872,0,3.467,2.329,3.467,5.2Z"
        />
        <path
          className="cls-7"
          d="M291.639,313.188a2.71,2.71,0,0,1-2.709,2.711H199.156a2.71,2.71,0,0,1-2.71-2.711h0a2.71,2.71,0,0,1,2.71-2.71H288.93a2.709,2.709,0,0,1,2.709,2.71Z"
        />
        <rect
          className={styles['cls-6']}
          x="136.652"
          y="127.007"
          width="214.781"
          height="143.742"
          rx="5.205"
          ry="5.205"
        />
        <path
          className={styles['cls-8']}
          d="M244.043,134.248c2.8,0,2.8-4.337,0-4.337s-2.8,4.337,0,4.337Z"
        />
        <path
          className={styles['cls-8']}
          d="M156.161,138.026h175.76A4.478,4.478,0,0,1,336.4,142.5v114.66a4.476,4.476,0,0,1-4.476,4.476H156.161a4.476,4.476,0,0,1-4.476-4.476V142.5A4.476,4.476,0,0,1,156.161,138.026Z"
        />
        <path
          className={styles['cls-9']}
          d="M201.822,201.48a1.65,1.65,0,0,1-1.167-2.816l15.333-15.331a1.65,1.65,0,0,1,2.333,2.334L202.988,201A1.647,1.647,0,0,1,201.822,201.48Z"
        />
        <path
          className={styles['cls-9']}
          d="M217.154,201.483a1.644,1.644,0,0,1-1.166-.483l-15.331-15.332a1.65,1.65,0,0,1,2.333-2.333l15.331,15.332a1.65,1.65,0,0,1-1.167,2.816Z"
        />
        <path
          className={styles['cls-9']}
          d="M270.931,201.48a1.65,1.65,0,0,1-1.167-2.816L285.1,183.333a1.65,1.65,0,0,1,2.334,2.334L272.1,201A1.651,1.651,0,0,1,270.931,201.48Z"
        />
        <path
          className={styles['cls-9']}
          d="M286.263,201.483A1.646,1.646,0,0,1,285.1,201l-15.33-15.332a1.65,1.65,0,1,1,2.334-2.333l15.33,15.332a1.65,1.65,0,0,1-1.167,2.816Z"
        />
        <path
          className={styles['cls-10']}
          d="M279.382,218.332v15.132a5.421,5.421,0,0,1-5.421,5.42h-2.924a5.42,5.42,0,0,1-5.42-5.42V218.332"
        />
        <polygon
          className={styles['cls-11']}
          points="274.876 219.122 272.499 236.738 270.122 219.122 274.876 219.122"
        />
        <path
          className={styles['cls-9']}
          d="M289.983,219.69H198.1a1.375,1.375,0,0,1,0-2.75h91.882a1.375,1.375,0,0,1,0,2.75Z"
        />
        <path
          className={styles['cls-12']}
          d="M183.064,216.074c8.739,0,8.739-13.551,0-13.551s-8.738,13.551,0,13.551Z"
        />
        <path
          className={styles['cls-12']}
          d="M305.022,216.074c8.738,0,8.738-13.551,0-13.551s-8.739,13.551,0,13.551Z"
        />
        <path
          className={styles['cls-13']}
          d="M316.539,109.248v44.718c0,4.369,6.776,4.369,6.776,0V109.248c0-4.369-6.776-4.369-6.776,0Z"
        />
        <path
          className={styles['cls-13']}
          d="M319.927,171.243c6.554,0,6.554-10.163,0-10.163s-6.554,10.163,0,10.163Z"
        />
        <path
          className={styles['cls-2']}
          d="M267.622,93.955C219.408,109.349,66.688,33.442,78.64,137.126c1.868,15.416,11.8,37.193,3.412,51.586-6.077,10.779-17.216,17.1-27.974,21.287-28.105,11.425-38.569,32.868-31.452,63.474,19.038,82.469,122.673,62.912,177.079,38.458,38.691-18.2,84.323-21.322,124.387-5.928,31.388,12.534,50.954,38.828,87.793,31.125,32.67-6.556,37.6-37.4,28.06-66.113-5.42-17.764-9.311-25.27,1.439-42.417,9.058-17.029,25-29.933,26.23-49.884.269-21.608-9.661-43.383-28.877-51.567-15.722-6.7-29.973-3.993-45.526-8.11-34.6-9.723-56.827-63.474-99.743-44.988-10.7,5.052-16.722,16.691-28.382,20.081"
        />
        <g className="cls-3">
          <path
            className={styles['cls-2']}
            d="M250.14,86.551c4.36,0,4.367-6.776,0-6.776s-4.367,6.776,0,6.776Z"
          />
          <path
            className={styles['cls-2']}
            d="M49.419,190.723c8.719,0,8.733-13.551,0-13.551s-8.734,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            d="M456.79,244.756c8.719,0,8.733-13.551,0-13.551s-8.734,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            d="M227.273,83.163c8.719,0,8.734-13.551,0-13.551-8.719,0-8.734,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            d="M470.341,223.245c11.335,0,11.353-17.617,0-17.617-11.336,0-11.354,17.617,0,17.617Z"
          />
          <path
            className={styles['cls-2']}
            d="M26.552,213.081c11.335,0,11.354-17.616,0-17.616-11.335,0-11.354,17.616,0,17.616Z"
          />
          <path
            className={styles['cls-2']}
            d="M402.586,107.216c11.336,0,11.354-17.616,0-17.616-11.335,0-11.353,17.616,0,17.616Z"
          />
          <path
            className={styles['cls-2']}
            d="M246.752,72.153c13.079,0,13.1-20.326,0-20.326-13.079,0-13.1,20.326,0,20.326Z"
          />
          <path
            className={styles['cls-2']}
            d="M477.625,239.166c6.539,0,6.55-10.163,0-10.163s-6.551,10.163,0,10.163Z"
          />
          <path
            className={styles['cls-2']}
            d="M24.18,184.794c6.54,0,6.551-10.163,0-10.163s-6.55,10.163,0,10.163Z"
          />
          <path
            className={styles['cls-2']}
            d="M423.76,117.04c6.539,0,6.55-10.163,0-10.163s-6.551,10.163,0,10.163Z"
          />
          <path
            className={styles['cls-2']}
            d="M422.066,98.408c4.359,0,4.367-6.776,0-6.776s-4.367,6.776,0,6.776Z"
          />
          <path
            className={styles['cls-2']}
            d="M78.893,86.719c8.719,0,8.733-13.551,0-13.551s-8.734,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            d="M464.412,320.3c8.719,0,8.734-13.551,0-13.551-8.719,0-8.734,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            d="M24.011,326.231c8.72,0,8.734-13.551,0-13.551s-8.733,13.551,0,13.551Z"
          />
          <path
            className={styles['cls-2']}
            d="M99.219,76.556c4.359,0,4.367-6.775,0-6.775s-4.367,6.775,0,6.775Z"
          />
          <path
            className={styles['cls-2']}
            d="M465.259,293.2c4.36,0,4.367-6.775,0-6.775s-4.367,6.775,0,6.775Z"
          />
          <path
            className={styles['cls-2']}
            d="M44.337,334.7c4.36,0,4.367-6.776,0-6.776s-4.367,6.776,0,6.776Z"
          />
        </g>
        <path
          className={styles['cls-4']}
          d="M141.058,220.624c-4.564,1.612-7.577,1.921-9.916-2.7-1.8-3.554-3-7.224-5.269-10.552-4.912-7.212-9.67-7.7-17.537-6.668-7.807,1.019-18.458-.975-19.643-10-.845-6.435-.894-12.352-3.711-18.372-1.388-2.966-5.768-.384-4.388,2.565,3.126,6.68,1.759,13.952,4.047,20.756,2.32,6.9,10.048,9.129,16.545,9.905,4.909.585,9.206-.6,14.02-.593,5.168.006,8.7,8.872,10.336,12.765,3.415,8.115,8.253,10.837,16.867,7.794,3.068-1.083,1.747-5.994-1.351-4.9Z"
        />
        <path
          className={styles['cls-4']}
          d="M87.357,165.474a15.194,15.194,0,0,1-5.595,4.352c-2.342,1.18-.283,4.687,2.052,3.511a19.547,19.547,0,0,0,7.053-5.811,2.033,2.033,0,0,0-.729-2.781,2.082,2.082,0,0,0-2.781.729Z"
        />
        <path
          className={styles['cls-4']}
          d="M80.078,160.064c.011,4.3.638,8.573.677,12.873.024,2.62,4.089,2.623,4.065,0-.039-4.3-.666-8.575-.677-12.873-.007-2.622-4.072-2.622-4.065,0Z"
        />
        <path
          className={styles['cls-4']}
          d="M73.221,164.211c2.72,3.4,5.482,6.792,9.218,9.126,2.231,1.392,4.27-2.126,2.052-3.511-3.411-2.13-5.915-5.389-8.4-8.489-1.638-2.047-4.5.849-2.874,2.874Z"
        />
        <path
          className={styles['cls-4']}
          d="M69.833,170.308a13.219,13.219,0,0,0,13.5,3.234c2.5-.764,1.433-4.689-1.08-3.921a9.1,9.1,0,0,1-9.541-2.187c-1.9-1.8-4.782,1.07-2.874,2.874Z"
        />
        <path
          className={styles['cls-4']}
          d="M348.892,233.237c.183-6.64,2.4-8.856,8.813-9.378,4.165-.34,8.39-.283,12.509-1.05,6.287-1.171,6.171-9.843,8.6-14.318,3.715-6.836,13.7-6.11,20.137-8.129,7.1-2.225,9.821-8.232,10.239-15.23.2-3.274-4.886-3.256-5.081,0-.623,10.41-8.258,10.772-16.643,12.5-7.391,1.521-12.4,5.572-14.966,12.7-.805,2.229-1,6.877-3.64,7.585-4.3,1.153-9.242.626-13.663,1.105-8.309.9-11.172,6.339-11.389,14.223-.091,3.279,4.991,3.27,5.081,0Z"
        />
        <path
          className={styles['cls-4']}
          d="M397.239,178.9a16.065,16.065,0,0,0,8.874,8.873,2.033,2.033,0,0,0,2.5-1.42,2.082,2.082,0,0,0-1.419-2.5,11.475,11.475,0,0,1-6.035-6.032,2.082,2.082,0,0,0-2.5-1.421,2.035,2.035,0,0,0-1.42,2.5Z"
        />
        <path
          className={styles['cls-4']}
          d="M403.264,173.614v10.164c0,2.621,4.066,2.621,4.066,0V173.614c0-2.621-4.066-2.621-4.066,0Z"
        />
        <path
          className={styles['cls-4']}
          d="M410.113,174.429a18.769,18.769,0,0,1-6.254,8.589c-2.032,1.658.863,4.516,2.875,2.874a22.567,22.567,0,0,0,7.3-10.383c.861-2.483-3.067-3.541-3.919-1.08Z"
        />
        <path
          className={styles['cls-4']}
          d="M415.061,178.686a15.619,15.619,0,0,1-7.594,5.842c-2.391.98-1.346,4.915,1.08,3.92,4.051-1.66,7.646-3.939,10.024-7.709,1.4-2.225-2.116-4.263-3.51-2.053Z"
        />
        <path
          className={styles['cls-5']}
          d="M373.117,306.863c0,3.555-26.614,4.587-29.49,4.587H144.458c-2.873,0-29.491-1.032-29.491-4.587l24.287-36.4c0-3.555,2.331-6.437,5.2-6.437H343.627c2.876,0,5.2,2.882,5.2,6.437Z"
        />
        <polygon
          className={styles['cls-6']}
          points="203.084 298.753 151.897 298.753 160.853 277.072 207.15 277.072 203.084 298.753"
        />
        <polygon
          className={styles['cls-6']}
          points="285.001 298.753 336.188 298.753 327.229 277.072 280.935 277.072 285.001 298.753"
        />
        <polygon
          className={styles['cls-6']}
          points="341.877 298.753 359.902 298.753 345.153 277.072 331.555 277.072 341.877 298.753"
        />
        <polygon
          className={styles['cls-6']}
          points="146.21 298.753 128.184 298.753 142.934 277.072 156.529 277.072 146.21 298.753"
        />
        <polygon
          className={styles['cls-6']}
          points="280.498 298.753 207.585 298.753 211.65 277.072 276.433 277.072 280.498 298.753"
        />
        <rect
          className={styles['cls-5']}
          x="132.633"
          y="281.029"
          width="222.822"
          height="3.319"
        />
        <rect
          className={styles['cls-5']}
          x="130.217"
          y="289.159"
          width="227.653"
          height="3.319"
        />
        <path
          className={styles['cls-6']}
          d="M374.48,315.3c0,2.874-.6,5.2-3.467,5.2H117.073c-2.877,0-3.469-2.329-3.469-5.2v-4.462c0-2.874.592-5.2,3.469-5.2h253.94c2.872,0,3.467,2.329,3.467,5.2Z"
        />
        <path
          className="cls-7"
          d="M291.639,313.188a2.71,2.71,0,0,1-2.709,2.711H199.156a2.71,2.71,0,0,1-2.71-2.711h0a2.71,2.71,0,0,1,2.71-2.71H288.93a2.709,2.709,0,0,1,2.709,2.71Z"
        />
        <rect
          className={styles['cls-6']}
          x="136.652"
          y="127.007"
          width="214.781"
          height="143.742"
          rx="5.205"
          ry="5.205"
        />
        <path
          className={styles['cls-8']}
          d="M244.043,134.248c2.8,0,2.8-4.337,0-4.337s-2.8,4.337,0,4.337Z"
        />
        <path
          className={styles['cls-8']}
          d="M156.161,138.026h175.76A4.478,4.478,0,0,1,336.4,142.5v114.66a4.476,4.476,0,0,1-4.476,4.476H156.161a4.476,4.476,0,0,1-4.476-4.476V142.5A4.476,4.476,0,0,1,156.161,138.026Z"
        />
        <path
          className={styles['cls-9']}
          d="M201.822,201.48a1.65,1.65,0,0,1-1.167-2.816l15.333-15.331a1.65,1.65,0,0,1,2.333,2.334L202.988,201A1.647,1.647,0,0,1,201.822,201.48Z"
        />
        <path
          className={styles['cls-9']}
          d="M217.154,201.483a1.644,1.644,0,0,1-1.166-.483l-15.331-15.332a1.65,1.65,0,0,1,2.333-2.333l15.331,15.332a1.65,1.65,0,0,1-1.167,2.816Z"
        />
        <path
          className={styles['cls-9']}
          d="M270.931,201.48a1.65,1.65,0,0,1-1.167-2.816L285.1,183.333a1.65,1.65,0,0,1,2.334,2.334L272.1,201A1.651,1.651,0,0,1,270.931,201.48Z"
        />
        <path
          className={styles['cls-9']}
          d="M286.263,201.483A1.646,1.646,0,0,1,285.1,201l-15.33-15.332a1.65,1.65,0,1,1,2.334-2.333l15.33,15.332a1.65,1.65,0,0,1-1.167,2.816Z"
        />
        <path
          className="cls-10"
          d="M279.382,218.332v15.132a5.421,5.421,0,0,1-5.421,5.42h-2.924a5.42,5.42,0,0,1-5.42-5.42V218.332"
        />
        <polygon
          className="cls-11"
          points="274.876 219.122 272.499 236.738 270.122 219.122 274.876 219.122"
        />
        <path
          className={styles['cls-9']}
          d="M289.983,219.69H198.1a1.375,1.375,0,0,1,0-2.75h91.882a1.375,1.375,0,0,1,0,2.75Z"
        />
        <path
          className={styles['cls-12']}
          d="M183.064,216.074c8.739,0,8.739-13.551,0-13.551s-8.738,13.551,0,13.551Z"
        />
        <path
          className={styles['cls-12']}
          d="M305.022,216.074c8.738,0,8.738-13.551,0-13.551s-8.739,13.551,0,13.551Z"
        />
        <path
          className={styles['cls-13']}
          d="M316.539,109.248v44.718c0,4.369,6.776,4.369,6.776,0V109.248c0-4.369-6.776-4.369-6.776,0Z"
        />
        <path
          className={styles['cls-13']}
          d="M319.927,171.243c6.554,0,6.554-10.163,0-10.163s-6.554,10.163,0,10.163Z"
        />
      </svg>
      <div
        style={{
          textAlign: 'center',
          fontSize: '18px',
          color: 'white',
          margin: '10px',
        }}
      >
        Error al cargar los modulos por favor reintente mas tarde
      </div>
    </div>
  );
}

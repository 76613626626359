import React from 'react';
import styled from 'styled-components';
import { Text } from '../../components/Base';
import { percentage } from '../../utils/percentage';

export const PositiveOrNegativeNumberColor = styled(Text)`
  color: ${({ number }) => (number < 0 ? '#fc5c1f' : '#00ff00')};
`;

const BoxStyle = styled(Text)`
  display: inline-block;
  border-radius: 10px;
  padding: 7px;
  background: #2f4a60;
  letter-spacing: 0.5px;
`;

export const DiffNumber = ({ number }) => (
  <PositiveOrNegativeNumberColor number={number}>
    {percentage(number)}
  </PositiveOrNegativeNumberColor>
);

export const DiffBox = ({ number }) => (
  <BoxStyle>
    <PositiveOrNegativeNumberColor number={number}>
      {percentage(number)}
    </PositiveOrNegativeNumberColor>
  </BoxStyle>
);

import styled from 'styled-components';

const Status = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: ${(props) => props.bg || 'black'};
`;

export default Status;

import React, { useState } from 'react';
import styled from 'styled-components';
import waves from '../../icons/waves.svg';
import { ReactComponent as ThermometerBg2 } from '../../icons/thermometer-2.svg';

const getThemeColor = ({ theme, progress }) => {
  if (progress > 100) return theme.colors.blue;
  if (progress >= 80) return theme.colors.green;
  if (progress >= 50) return theme.colors.yellow;
  if (progress >= 25) return theme.colors.orange;
  else return theme.colors.red;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 152px;
  height: 162px;
  padding: 1rem 2rem;
  background: #3c5c7a;
  border-radius: 18px;
  box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.09);
  position: relative;

  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -40px;
    }
  }

  > div {
    position: relative;
    background: #4d7691;
    overflow: hidden;

    .progress {
      height: ${(props) => `${props.progress + 3}%`};
      position: absolute;
      width: 100%;
      bottom: 0;
      z-index: 1;
      background: ${(props) => `linear-gradient(
        to bottom,
        ${getThemeColor(props).light},
        ${getThemeColor(props).dark}
      )`};
      transition: all 0.4s linear;

      &::before {
        content: '';
        position: absolute;
        height: 22px;
        top: -18px;
        background: url(${waves}) repeat-x;
        width: 200px;
        animation: wave 2s ease-in-out;
      }

      #pattern {
        color: ${(props) => props.theme.colors.red.light};
      }
    }

    svg {
      display: block;
      position: relative;
      z-index: 2;
    }
  }
`;

const Thermometer = ({ progress }) => {
  const [currentProgress, setProgress] = useState(0);

  // useEffect(() => {
  setTimeout(() => setProgress(progress), 600);
  // setTimeout(() => setProgress(56), 1200);
  // setTimeout(() => setProgress(80), 1800);
  // setTimeout(() => setProgress(101), 2500);
  // }, []);

  return (
    <Wrapper progress={currentProgress}>
      <div>
        <div className="progress" />
        <ThermometerBg2 />
      </div>
    </Wrapper>
  );
};
export default Thermometer;

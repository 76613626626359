import React from 'react';
import styled from 'styled-components';

export const PodiumItem = ({ children, position }) => (
  <div className="podium-item">
    <span className="position">{position}</span>
    {children}
  </div>
);

export const Label = styled.div`
  display: block;
  background: ${props => props.bg};
  border-radius: 15px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 11px;
  font-family: 'Boston';
  padding: 0rem 0.6rem;
`;

export const Podium = styled.div`
  display: inline-flex;

  @media screen and (max-width: 1024px) {
    transform: scale(0.72);
  }

  @media screen and (min-width: 1138px) and (max-height: 712px) {
    transform: scale(0.75) translateX(35px);
  }

  .podium-item {
    background: rgba(255, 255, 255, 0.12);
    border-radius: 24px;
    width: 115px;
    height: 180px;
    margin-right: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 24px;

    &:last-child {
      margin-right: 0;
    }

    .position {
      position: absolute;
      top: 8px;
      right: 10px;
      color: rgba(255, 255, 255, 0.3);
      font-family: 'Boston';
      font-size: 40px;
      z-index: 0;
    }

    ${Label} {
      position: relative;
      z-index: 2;
      margin-top: 16px;
    }
  }
`;
